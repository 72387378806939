<template>
  <div class="flex-row border-bottom" style="padding: 0px 20px; height: 60px">
    <el-menu
      class="flex-1 none-border"
      :default-active="currentPage === '/home' ? 'main-0' : currentModuleName"
      mode="horizontal"
    >
      <el-menu-item :index="'main-0'" @click="handleSelect">
        <i class="el-icon-menu"></i>
        <span slot="title">工作台</span>
      </el-menu-item>
      <el-menu-item
        v-for="(item, index) in menuList"
        :key="index + 'menus'"
        :index="item.moduleName"
        @click="handleSelect(item)"
      >
        <i class="el-icon-menu"></i>
        <span slot="title">{{ item.platformName }}</span>
      </el-menu-item>
    </el-menu>
    <div class="flex-row flex-items-center">
      <div style="padding-right: 20px">{{ nickname }}</div>
      <!-- <div class="menu-icons">
        <span class="menu-icon">
          <i class="el-icon-search icon" />
        </span>
        <span class="menu-icon">
          <i class="el-icon-message icon" />
        </span>
        <span class="menu-icon">
          <el-badge is-dot class="item">
            <i class="el-icon-bell icon" />
          </el-badge>
        </span>
      </div> -->
      <!-- <div class="cursor-pointer" style="height: 60px">
        <span v-if="!isScresnFull" class="qiankun-font iconscreen-full" @click="screenfullClick()"></span>
        <span v-else class="qiankun-font iconscreen-exit" @click="screenfullClick()"></span>
      </div> -->
      <el-dropdown @command="handleCommand">
        <div class="flex-row flex-items-center cursor-pointer">
          <el-avatar class="border" :src="avatarImg"></el-avatar>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import screenfull from "screenfull";
import { USER_KEY } from "@/config";
import { removeLocalStorage } from "@/utils/storage";
import { userInfo } from "@/utils/userInfo";
import { resetRouter } from "@/router"

export default {
  data() {
    return {
      avatarImg: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      isScresnFull: false,
    };
  },
  computed: {
    ...mapState({
      currentModuleName: (state) => state.permission.currentModuleName,
      nickname: (state) => state.user.userInfo.nickname,
      menuList: (state) => state.permission.menuList,
      tabsList: (state) => state.tabs.tabsList,
      currentPage: (state) => state.permission.currentPage,
    }),
  },
  methods: {
    toHome() {
      const homeMenuData = {
        title: "工作台",
        menuName: "home",
        menuPath: "/home",
        meta: { isTabs: false, isSide: true, moduleName: "main", title: "工作台" },
      };
      this.$store.commit("tabs/UPDATE_TABS_LIST", homeMenuData);
      this.$actions.setGlobalState({ tabsList: this.tabsList });

      this.$router.push(`/home`);

    },
    handleSelect(item) {
      if(item.platformName === "数据分析平台"){
        this.$sensors.track("PlatformGet",userInfo())
      }
      if (item.index === "main-0") {
        this.toHome();
        this.$store.commit("permission/UPDATE_SUB_MENU", true);
      } else {
        const menu = this.menuList.filter((element) => {
          return element.moduleName === item.moduleName;
        });
        this.$store.commit("permission/UPDATE_SUB_MENU", menu[0].menuList);
      }
    },
    handleCommand(command) {
      if (command === "logout") {
        removeLocalStorage(USER_KEY);
        this.$store.commit("user/UPDATE_USER_INFO", {});
        resetRouter();
        this.$store.commit("tabs/SET_TABS_LIST", [false]);
        localStorage.removeItem('selectedName')
        localStorage.removeItem('selectedOption')
        // this.$router.push({ path: "/login" });
        location.reload();
      }
      if (command === "setting") {
        this.$store.commit("user/UPDATE_USER_INFO_ITEM", { key: "userInfo", value: { name: "zhangsan" } });
        this.$actions.setGlobalState({ userInfo: { name: "zhangsan" } });
        return false;
      }
    },
    screenfullClick() {
      if (screenfull.isEnabled) {
        screenfull.toggle();
        this.isScresnFull = !this.isScresnFull;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.qiankun-font {
  font-size: 22px;
  padding-right: 20px;
  line-height: 60px;
}
.menu-icons {
  padding-right: 20px;
  display: flex;
  align-items: center;
  .menu-icon {
    padding: 0 12px;
    cursor: pointer;
    .icon {
      display: inline-block;
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>
