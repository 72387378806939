

export default {
  // 菜案管理
  menuManage: {
    // 添加
    add: "system_menuManage_add",
    // 编辑
    edit: "system_menuManage_edit",
    // 删除
    delete: "system_menuManage_delete",
  },
};
