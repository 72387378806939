import { fetchApi } from '@/utils/axios';
// 获取项目
export const getProjectList = (data) => {
  return fetchApi('/api/project/saas/project/userRole/treeProjectList', data, 'POST', 'data');
};
// 获取待办事项分页列表
export const needMatterGetList = (data) => {
  return fetchApi('/api/ds-operations-management/web/workbenchSchedule/getList', data, 'get');
};
// 根据ids更新待办事项已读状态
export const needMatterUpdateReadState = (data) => {
  return fetchApi(
    '/api/ds-operations-management/web/workbenchSchedule/updateReadState?ids=' + data,
    null,
    'PUT',
    'data'
  );
};
// 工作台房源招商
export const getHouseRecruitmentInfo = (data) => {
  return fetchApi('/api/ds-operations-management/web/workbenchSchedule/workbench/getHouseRecruitmentInfo', data, 'get');
};
// // i友工作台获取工单信息
export const getWorkOrderInfo = (data) => {
  return fetchApi('/api/dscloud-workorder-new/webWorkorder/workbench/getWorkOrderInfo', data, 'get');
};
// 工作台回款
export const getPaymentCollection = (data) => {
  return fetchApi('/api/dscloud-assets/fn/web/platform/info', data, 'POST', 'data');
};
// 工作台房源招商
export const getWorkBenchModule = (data) => {
  return fetchApi('/api/dscloud-authority-center/userRole/getWorkBenchModule', data, 'get');
};

// 工作台线索
export const getClueMessage = (data) => {
  return fetchApi('/api/dscloud-assets/cl/webb/clue-info/statistics-a01', data, 'get');
};
// 工作台线索
export const getCustomMessage = (data) => {
  return fetchApi('/api/dscloud-assets/cu/webb/customer-info/statistics-a01', data, 'get');
};
// i友工作台获取会议室信息
export const getMeetingInfo = (data) => {
  return fetchApi('/api/place/meeting/workbench/getMeetingInfo', data, 'get');
};
// 工作台需求池
export const getDemandPool = (data) => {
  return fetchApi('/api/dscloud-ies-demand/demand_pool/web/data_board_show', data, 'POST', 'data');
};
// 工作台客户跟进
export const getCustomFollowUp = (data) => {
  return fetchApi('/api/dscloud-assets/cl/webb/opportunity-follow/statistics-a01', data, 'get');
};