import store from "@/store";
import { checkDataType } from "../checkDataType"

import baseSet from "./buttonPermissionsConfig/baseSet";
import system from "./buttonPermissionsConfig/system";
import authManage from "./buttonPermissionsConfig/authManage";

/**
 * 校验按钮权限
 * @param {*} element 按钮组件标识
 * @returns Boolean true(包含)/false(不包含)
 */
export const checkButtonPermissions = element => {
  // 按钮权限集合
  const buttonPermissions = store.getters["permission/getButtonPermissions"];
  return buttonPermissions.includes(element);
};

/**
 * 校验是否显示列表页操作栏
 * @param {*} elements 操作栏按钮集合
 * @returns Boolean true(显示)/false(不显示)
 */
export const checkTableActionIsShow = elements => {
  // 按钮权限集合
  const buttonPermissions = store.getters["permission/getButtonPermissions"];
  let show = false;
  for (let index = 0; index < elements.length; index++) {
    if (checkDataType(elements[index]) === "Object") {
      if (buttonPermissions.includes(elements[index].permissions)) {
        show = true;
        break;
      }
    }else {
      if (buttonPermissions.includes(elements[index])) {
        show = true;
        break;
      }
    }
  }
  return show;
};

/**
 * 返回列表操作配置
 * @param {*} configurations 自定义配置参数
 * @returns 操作配置数据
 */
export const backTableAction = configurations => {
  const configKeyList = configurations && Object.keys(configurations);
  let tableAction = {
    title: "操作",
    key: "action",
    className: "action-ql-list",
    scopedSlots: { customRender: "action" },
  };
  if (configKeyList?.length) {
    tableAction = { ...tableAction, ...configurations };
  }
  return tableAction;
};

// 按钮权限配置
export const buttonPermissionsConfig = {
  // 基础设置
  baseSet,
  // 系统管理
  system,
  // 权限管理
  authManage,
};

export default {
  checkButtonPermissions,
  checkTableActionIsShow,
  backTableAction,
  buttonPermissionsConfig,
};
