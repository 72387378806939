/**
 * 用户登录、权限相关
 */

import { fetchApi } from "@/utils/axios";
import API from "../urlConfig";
import { getLocalStorage } from "@/utils/storage"

// 登录
export const login = (user) => {
  return fetchApi(API.LOGIN,
    {...user, grant_type: "double_check_password", scope: "all",},
    "post",
    "params");
};
/**
* @description 发送验证码
* @author cxd
* @param 
* @time 2022-03-08 11:29:16
*/
export const sendNote = (data) => {
  return fetchApi(`${API.SEND_NOTE}/${'login'}/${data}`)
}
/**
* @description 校验验证码是否正确
* @author cxd
* @param 
* @time 2022-03-08 15:51:17
*/
export const checkNote = (userId,formAuthCode) => {
  let parmas = {
    sms_code: formAuthCode.authCode,
    grant_type: 'double_check_sms_code',
    user_id: userId
  }
  return fetchApi(API.CHECK_NOTE, parmas, 'post')
}

// 校验用户是否是i友工作台用户
export const userCheck = () => {
  const submitData = {
    commonToken: getLocalStorage("user").commonToken
  }
  return fetchApi(API.USER_CHECK, submitData);
}

