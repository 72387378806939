
<template>
  <div :class="`ds-table ${dataSource && dataSource.length ? '' : 'no-data'}`">
    <a-table
      :pagination="newPagination"
      :columns="newColumns"
      :scroll="scroll ? scroll : {}"
      v-on="$listeners"
      v-bind="$props"
    >
      <template slot="customTitle">
        <slot name="customTitle"></slot>
      </template>

      <template
        v-for="(column, columnIndex) in columns"
        :slot="column.scopedSlots ? column.scopedSlots.customRender : ''"
        slot-scope="text, record, index"
      >
        <div :key="columnIndex">
          <slot :name="column.scopedSlots ? column.scopedSlots.customRender : ''" v-bind="record" v-bind:index="index">
          </slot>
        </div>
      </template>
      <template :slot="expandedRowRender ? 'expandedRowRender' : 'undefind'" slot-scope="record">
        <slot name="expandedRowRender" v-bind="record"></slot>
      </template>
    </a-table>
  </div>
</template>

<script>
const defaultPagination = {
  pageSize: 10,
  current: 1,
  pageSizeOptions: ["10", "20", "30", "50", "100"],
  "show-size-changer": true,
  "show-quick-jumper": true,
  showTotal(total) {
    return `共${total}条`;
  },
};

import { Table } from "ant-design-vue";
export default {
  name: "ds-table",
  components: {},
  props: {
    ...Table.props,
    expandedRowRender: {
      type: Boolean,
      default: null,
    },
    pagination: {
      default() {
        return defaultPagination;
      },
    },
    // 隐藏配置过hidden为true的列
    hideConfigColumns: {
      type: Boolean,
      default: false,
    },
    // 请求函数
    requestFn: {
      type: String,
      default: "",
    },
    // 请求参数
    requestParam: {
      type: Object,
      default: null,
    },
    // 滚动距离
    scroll: {
      type: Object,
      default: null,
    },
  },
  computed: {
    newPagination() {
      if (!this.pagination) {
        return false;
      }

      return {
        ...defaultPagination,
        ...this.pagination,
      };
    },
    // 通过hideConfigColumns控制columns中配置过hidden为true的列隐藏
    newColumns() {
      let columns = this.columns;
      let newColumns = [];
      if (this.hideConfigColumns) {
        columns.map((column) => {
          if (!column.hidden) {
            newColumns.push(column);
          }
        });
      }
      return newColumns && newColumns.length ? newColumns : columns;
    },
  },
  data() {
    return {
      paginationData: defaultPagination,
      variableColumns: [],
      isload: true,
    };
  },

  methods: {
    expand(expanded, record) {
      this.$emit("expand", expanded, record);
    },

    // 获取省略容器真实宽度
    getEllipsisContentWidth(column) {
      let columnWidth = parseInt(column.width || 200 + ANT_TABLE_LR_PADDING);
      return columnWidth - ANT_TABLE_LR_PADDING;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>



