// emoji表情
const emojiFace =
  /(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g;

// 禁止输入特殊字符和emoji表情公共方法
const forbiddenSpecialCharsEmoji = (value) => {
  if (value.target) {
    value.target.value = value.target.value.replace(
      /[`~!@#$%^&*()_\-+=<>?:"{}|,./;"·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、" "～]/g,
      ""
    );
    value.target.value = forbidEmoji(value);
    return value.target.value.trim();
  }
};

// 禁止输入emoji
const forbidEmoji = (value) => {
    value.target.value = value.target.value.replace(emojiFace, "");
    return value.target.value;
};

// 数字键盘校验
const validNumKeyBoard = (value) => {
  value.target.value = forbiddenSpecialCharsEmoji(value);
  value.target.value = value.target.value.replace(/[^0-9]/g, "");
  return value.target.value;
};
// 只允许输入字母数字
const validAllowCharNum = (value) => {
  value.target.value = value.target.value.replace(/[^a-zA-Z0-9]/g, "");
  return value.target.value;
};
// 姓名校验
const nameVerify = (value) => {
  value.target.value = forbiddenSpecialCharsEmoji(value);
  value.target.value = value.target.value.replace(/[0-9]/g, "");
  return value.target.value;
};
//坐标校验
const pointVerify = value => {
  value.target.value = value.target.value.replace(/[^0-9.]/g, "");
  return value.target.value;
}

export default {
  validNumKeyBoard,
  nameVerify,
  validAllowCharNum,
  forbiddenSpecialCharsEmoji,
  forbidEmoji,
  pointVerify
};
