<template>
  <div>
    <div v-if="includesData(6) || includesData(7)">
      <div class="displayFlexCenter viewTitle">
        <div class="title">企业运营</div>
        <div class="projectTitle">
          项目：<a-tree-select
            @change="projectChange"
            :allowClear="true"
            :replaceFields="fieldName"
            v-model="projectId"
            tree-default-expand-all
            style="width: 200px"
            :tree-data="projectList"
            placeholder="请选择项目"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
          />
        </div>
      </div>
      <div class="displayFlex housingResources">
        <div class="flex1">
          <template v-if="includesData(6)">
            <div class="graphsTitle">需求响应</div>
            <div class="switchBox">
              <div class="switchView" @click="switchBut(false)" :class="checkYear ? '' : 'switchActive'">本月</div>
              <div class="switchView" @click="switchBut(true)" :class="checkYear ? 'switchActive' : ''">本年</div>
            </div>
          </template>
          <div class="graphsBox">
            <div class="displayFlex" style="flex: auto; align-items: center">
              <template v-if="includesData(6)">
                <div class="graphsTitleView" @click="jumpRouter('operation', '/operation/demand-pool-shunyi/workOrder')">
                  <div class="graphsTitleViewTitle">
                    <img class="iconImg" src="../../../assets/home/home (11).png" alt="" srcset="" />
                    <span class="title">需求工单</span>
                  </div>
                  <div class="graphsTitleViewSpace">
                    <span class="number">{{ demandPoolForm.count }}</span>
                    <span class="unit">个</span>
                  </div>
                </div>
                <div class="graphsTitleView marginLeft20" @click="jumpRouter('operation', '/operation/demand-pool-shunyi/workOrder')">
                  <div class="graphsTitleViewTitle">
                    <img class="iconImg" src="../../../assets/home/home (12).png" alt="" srcset="" />
                    <span class="title">需求解决率</span>
                  </div>
                  <div class="graphsTitleViewSpace">
                    <span class="number">{{ demandPoolForm.resolutionRate }}</span>
                    <span class="unit">%</span>
                  </div>
                </div>
              </template>

              <div class="boder" v-if="includesData(6) && includesData(7)"></div>

              <template v-if="includesData(7)">
                <div class="graphsTitleView" @click="jumpRouter('asset', '/asset/opportunity/opportunityManagement')">
                  <div class="graphsTitleViewTitle">
                    <img class="iconImg" src="../../../assets/home/home (10).png" alt="" srcset="" />
                    <span class="title">即将跟进商机</span>
                  </div>
                  <div class="graphsTitleViewSpace">
                    <span class="number">{{ customForm.waitFollowCount }}</span>
                    <span class="unit">个</span>
                  </div>
                </div>
                <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset', '/asset/opportunity/opportunityManagement')">
                  <div class="graphsTitleViewTitle">
                    <img class="iconImg" src="../../../assets/home/home (16).png" alt="" srcset="" />
                    <span class="title">超时未跟进</span>
                  </div>
                  <div class="graphsTitleViewSpace">
                    <span class="number">{{ customForm.noFollowAndExpiresCount }}</span>
                    <span class="unit">个</span>
                  </div>
                </div>
                <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset', '/asset/opportunity/opportunityManagement')">
                  <div class="graphsTitleViewTitle">
                    <img class="iconImg" src="../../../assets/home/home (16).png" alt="" srcset="" />
                    <span class="title">超时7天未跟进</span>
                  </div>
                  <div class="graphsTitleViewSpace">
                    <span class="number">{{ customForm.noFollowIn7DaysCount }}</span>
                    <span class="unit">个</span>
                  </div>
                </div>
                <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset', '/asset/opportunity/opportunityManagement')">
                  <div class="graphsTitleViewTitle">
                    <img class="iconImg" src="../../../assets/home/home (16).png" alt="" srcset="" />
                    <span class="title">超时30天未跟进</span>
                  </div>
                  <div class="graphsTitleViewSpace">
                    <span class="number">{{ customForm.noFollowIn30DaysCount }}</span>
                    <span class="unit">个</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/home';
import { colorList, jumpAppointRouter } from '../index';
export default {
  props: ['projectList', 'roleId', 'menuList'],
  data() {
    return {
      colorList,
      projectId: null,
      checkYear: false,
      customForm: {}, //客户跟进
      demandPoolForm: {}, //需求池
      fieldName: {
        value: 'id',
        key: 'id',
        title: 'project_name',
        children: 'child',
      },
    };
  },
  mounted() {
    this.getDemandPool();
    this.getCustomFollowUp();
  },
  methods: {
    switchBut(val) {
      if (val == this.checkYear) {
        return;
      }
      this.checkYear = val;
      this.getDemandPool();
    },
    async getDemandPool() {
      await api
        .getDemandPool({ dateType: this.checkYear ? 'year' : 'month', projectId: this.projectId })
        .then((res) => {
          if (res.code === '200') {
            this.demandPoolForm = res.data;
          }
        });
    },
    async getCustomFollowUp() {
      await api.getCustomFollowUp({ projectId: this.projectId, periodType: '20' }).then((res) => {
        if (res.code === '200') {
          this.customForm = res.data;
        }
      });
    },
    projectChange() {
      this.getDemandPool();
      this.getCustomFollowUp();
    },
    includesData(val) {
      return this.menuList.includes(val);
    },
    //跳转页面地址
    jumpRouter(moduleName, path) {
      jumpAppointRouter(moduleName, path);
    },
  },
};
</script>

<style lang="scss" scoped>
.displayFlexCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.displayFlex {
  display: flex;
}

.viewTitle {
  padding-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
  margin-top: 40px;
  // border-top: 1px solid #EEEEEE;
  // padding-top: 20px;

  .title {
    font-weight: 500;
    font-size: 18px;
    color: #222222;
  }

  .projectTitle {
    font-size: 14px;
    font-weight: 400;
    color: #222222;
  }
}

.marginLeft4 {
  margin-left: 4px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginLeft80 {
  margin-left: 80px;
}

.housingResources {
  padding: 16px 0px;
  align-items: center;
}

.graphsBox {
  height: inherit;
  display: flex;
  align-items: center;

  .graphsTitleView {
    background: #fafafa;
    flex: 1;
    padding: 20px 0px 20px 20px;
    cursor: pointer;

    .graphsTitleViewTitle {
      display: flex;
      align-items: center;

      .title {
        margin-left: 4px;
      }
    }

    .graphsTitleViewSpace {
      margin-top: 16px;

      .number {
        font-size: 22px;
        color: #222222;
        font-weight: 700;
      }

      .unit {
        font-size: 12px;
        color: #555555;
        margin-left: 4px;
      }
    }
  }
}

.flex1 {
  flex: 1;
}

.graphsTitle {
  font-size: 14px;
  color: #222222;
  font-weight: 500;
}

.iconImg {
  height: 20px;
  width: 20px;
}

.boder {
  height: 60px;
  border-left: 1px solid #eeeeee;
  margin-left: 40px;
  margin-right: 40px;
}

.switchBox {
  width: 108px;
  height: 28px;
  background: #f2f3f8;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 16px;

  .switchView {
    font-weight: 400;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    width: 48px;
    text-align: center;
    cursor: pointer;
  }

  .switchActive {
    color: #1664ff;
    background: #ffffff;
    border-radius: 2px;
  }
}
</style>
