import moment from "moment"
export function userInfo () {
    const user = JSON.parse(localStorage.getItem('user'))
    const time = moment(new Date()).format("YYYY/MM/DD HH:mm:ss")
    return {
        new_product_id: user.username,
        name: user.nickname,
        alltel: user.mobile,
        click_time: time,
    }
}