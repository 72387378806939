import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import * as echarts from 'echarts'
import "ant-design-vue/dist/antd.css";
import "qiankun-vue2-common/style/index.scss";
import "qiankun-vue2-common/iconfont/iconfont.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/icon/iconfont/iconfont.css";
import InputVerify from "@/utils/inputVerify";
import DSTable from "@/components/DSComponents/DSTable"
import DSHeader from "@/components/DSComponents/DSHeader"
import DSButton from "@/components/DSComponents/DSButton"
import DSBtnConfig from "@/utils/DSBtnConfig"
import { SUCCESS_CODE } from "@/config"
import buttonPermissions from "@/utils/buttonPermissions"

// 兼容ie浏览器
import "babel-polyfill";

import sensors from "sa-sdk-javascript";
// 埋点
// 'http://data-warehouse.innoecos.cn:9001/sa?project=default'  测试
// 'http://data-warehouse.innoecos.cn:9001/sa?project=production' 生产
let pointsAddress = ''
if (process.env.NODE_ENV === 'production') {
  pointsAddress = 'https://data-warehouse.innoecos.cn/sa?project=production';
} else {
  pointsAddress = 'https://data-warehouse.innoecos.cn/sa?project=default';
}
sensors.init({
    server_url: pointsAddress,
    // app_js_bridge: true,
    // show_log :true, // 是否开始log日志
    heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'default',
        //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'not_collect',
    }
});
Vue.prototype.$sensors = sensors
Vue.prototype.$CODE = SUCCESS_CODE

Vue.use(ElementUI, { size: "small" });
Vue.use(Antd);
Vue.prototype.$echarts = echarts;
Vue.component("ds-table", DSTable);
Vue.component("ds-header", DSHeader);
Vue.component("ds-button", DSButton);

import validator from "validator";
Vue.prototype.$validator = validator;
import http from "./utils/axios";
Vue.prototype.$http = http;

import { parse, stringify } from "qs";
Vue.prototype.$qsParse = parse;
Vue.prototype.$qsStringify = stringify;
// 按钮类型
Vue.prototype.$DSBT = DSBtnConfig;

Vue.config.productionTip = false;

import { qiankunActions } from "./qiankun/index";

Vue.prototype.$actions = qiankunActions;

// 键盘校验
Object.keys(InputVerify).forEach(key => {
  Vue.prototype[`${key}`] = InputVerify[key];
})

// 全局loading属性
Vue.prototype.$isLoading = store.getters.isLoading;

// 校验按钮权限
Object.keys(buttonPermissions).forEach(key => {
  Vue.prototype[`$${key}`] = buttonPermissions[key];
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#main");
