<template>
  <div class="container" :style="containerStyle">
    <div class="kc_logo" v-if="isKcjt">
      <div>
        <img src="../assets/images/kcjt-icon.png" alt="">
      </div>
      <div>
        <img src="../assets/images/kcjt-name-img.png" alt="">
      </div>
    </div>
    <div class="login">
      <a-tabs
        default-active-key="1"
        :tabBarStyle="{
          color: '#A8A8A8',
        }"
      >
        <a-tab-pane key="1" :tab="titleName">
          <div v-show="showLoginType">
            <a-form-model ref="form" :model="formData" :rules="rules" @submit="handleSubmit" @submit.native.prevent>
              <a-form-model-item prop="username">
                <a-input v-model.trim="formData.username" placeholder="请输入用户名">
                  <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item prop="password">
                <a-input v-model.trim="formData.password" type="password" placeholder="请输入密码">
                  <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item style="margin-bottom: 0">
                <a-button :loading="loading" type="primary" html-type="submit"> 登录 </a-button>
              </a-form-model-item>

              <!--            <a-form-model-item style="text-align: right">
              <span class="forget">忘记密码</span>
            </a-form-model-item>-->
            </a-form-model>
          </div>
          <div v-show="!showLoginType">
            <div style="font-size: 10px;margin-top:30px;margin-bottom:35px">请输入发送至 {{ userPhone }} 的验证码，并将其填写至下方位置</div>
            <a-form-model
              ref="codeForm"
              :model="formAuthCode"
              :rules="codeRules"
            >
              <a-form-model-item prop="authCode">
                <a-input  v-model="formAuthCode.authCode" :max-length="6" placeholder="请输入验证码">
                  <a-icon slot="prefix" type="safety-certificate" style="color: rgba(0, 0, 0, 0.25)" />
                  <a-tooltip slot="suffix">
                    <a style="color: #40a9ff"  @click="sendAuthCode" v-show="showSendCodeButton">重新发送</a>
                    <a disabled v-show="!showSendCodeButton">{{ countDown }}秒后重新获取</a>
                  </a-tooltip>
                </a-input>
              </a-form-model-item>
              <a-form-model-item style="margin-bottom: 0">
                <a-button :loading="loading" type="primary"  @click="submitAuthCode"> 确认 </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-tab-pane>
        <!--        <a-tab-pane key="2" tab="验证码登录" force-render>
          Content of Tab Pane 2
        </a-tab-pane>-->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { login,sendNote,checkNote } from '@/api/userAuth';
import { setLocalStorage,getLocalStorage } from '@/utils/storage';
import { USER_KEY, getPlatformAccount } from '@/config';
import logo from '@/assets/logo.png'
import kcLogo from '@/assets/kc_logo.png'
import login_bg from '@/assets/login_bg.png'


export default {
  name: 'Login',
  mounted() {
    sessionStorage.removeItem('currentApp');
    sessionStorage.removeItem('currentPage');
    this.$actions.setGlobalState({ userInfo: { name: 'lizhijie429' } });
  },
  computed: {
    isKcjt() {
      return window.location.href.includes('kcjt')
    }
  },
  watch: {
    $route: {
      handler(val) {
        if(window.location.href.includes('kcjt')) {
          this.containerStyle = {
            // background: `url("${kcLogo}") no-repeat 50px 25px/16%, url(${login_bg}) no-repeat top left/100%`
            background: `url(${login_bg}) no-repeat top left/100%`
          }
        } else {
          this.containerStyle = {
            background: `url("${logo}") no-repeat 50px 25px/16%, url(${login_bg}) no-repeat top left/100%`
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      containerStyle: {},
      // tab名称
      titleName: '账号登录',
      // 动态展示登录方式
      showLoginType: true,
      // 验证码
      formAuthCode: {
        authcode: undefined,
      },
      // 用户手机号
      userPhone: '',
      // 重新发送验证码按钮的展示
      showSendCodeButton: true,
      loading: false,
      // 用户名、密码
      formData: {
        username: '',
        password: '',
      },
      timer: null,
      // 倒计时
      countDown: 60,
      rules: {
        username: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
      },
      codeRules: {
        authCode: [
            { required: true, message: '验证码不能为空', trigger: 'blur' },
            { required: true, min: 6, message: '验证码有误！请输入正确的验证码', trigger: 'blur' },
            { pattern: /^\d{6}$/,  message: '验证码有误！请输入正确的验证码', trigger: 'blur' }
        ],
      },
    };
  },
  methods: {
    // 跳转首页
    toHome() {
      this.$router.push(process.env.VUE_APP_DEFAULT_APP);
    },
    // 登录
    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const data = await login(this.formData);
          if (data.code === '200') {
            if (data.data.factorStatus) {
              this.showLoginType = false;
              this.titleName = '验证码校验';
              // 手机号中间用*代替
              const reg=/(\d{3})\d{4}(\d{4})/; //正则表达式
              let phone = data.data.mobile
              this.userPhone= phone.replace(reg, "$1****$2")
              this.localSsaveUserInfo(data.data)
              // 开始倒计时
              this.toCountDown()
            } else {
              this.localSsaveUserInfo(data.data)
              this.$router.push({ path: '/home' });
            }
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        }
      });
    },
    // 存储用户信息
    localSsaveUserInfo(data) {
      this.$store.commit('user/UPDATE_USER_INFO', data);
      setLocalStorage(USER_KEY, data);
      getPlatformAccount();
    },
    /**
     * @description 判断用户是否是双因子认证 ---双因子认证：需要验证码认证
     * @author cxd
     * @param
     * @time 2022-03-03 11:17:06
     */
    checkUserLoginType() {
      return true;
    },
    /**
    * @description 重新发送验证码
    * @author cxd
    * @param
    * @time 2022-03-04 14:15:30
    */
    async sendAuthCode () {
      const usereId = getLocalStorage(USER_KEY).userId
      // 开始倒计时
      this.toCountDown()
      // 调取发送验证码接口
      const data = await sendNote(usereId);
      if (data.code !== '200') {
        this.$message.error(data.msg)
      }
    },
    /**
    * @description 开始倒计时
    * @author cxd
    * @param
    * @time 2022-03-04 15:36:09
    */
    toCountDown() {
      this.showSendCodeButton = false
      // 启动定时器
      this.countDown -= 1; //定时器-1
      this.timer = setTimeout(() => {
        // 创建定时器
        if (this.countDown === 0) {
          // 设置的定时器时间为0后执行的操作
          this.timer && this.clearTime(); //关闭定时器
        } else {
          this.toCountDown();
        }
      }, 1000);
    },
    /**
    * @description 发送验证码
    * @author cxd
    * @param
    * @time 2022-03-08 10:59:06
    */
    /**
    * @description 关闭定时器
    * @author cxd
    * @param
    * @time 2022-03-04 15:57:42
    */
    clearTime() {
      // 清除定时器
      clearInterval(this.timer);
      this.timer = null;
      this.countDown = 60;
      this.showSendCodeButton = true;
    },
    /**
    * @description  校验验证码
    * @author cxd
    * @param
    * @time 2022-03-04 14:15:39
    */
    submitAuthCode() {
      this.$refs.codeForm.validate(async (valid) => {
        if (valid) {
          const userId = getLocalStorage(USER_KEY).userId
          const data = await checkNote(userId,this.formAuthCode);
          if (data.code !== '200') {
            this.$message.error(data.msg);
          } else {
            this.localSsaveUserInfo(data.data)
            this.$router.push({ path: '/home' });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  // background: url("../assets/logo.png") no-repeat 50px 25px/16%, url("../assets/login_bg.png") no-repeat top left/100%;
  height: 100vh;
  padding-top: 18%;
  .kc_logo {
    position: absolute;
    top: 80px;
    left: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div:nth-child(1) {
      img {
        width: 50px;
        height: 40px;
      }
    }
    & > div:nth-child(2) {
      margin-left: 10px;
      img {
        width: 284px;
        height: 36px;
      }
    }
  }
  .login-bg {
    width: 100%;
  }

  .login {
    box-sizing: border-box;
    padding: 30px 20px 20px;
    margin: 0 auto;
    background-color: #fff;
    width: 400px;
    height: 350px;
    border-radius: 4px;
    z-index: 999;

    .ant-tabs-tab {
      color: #666;
      font-weight: bold;

      &:hover {
        color: #666666;
      }
    }

    .ant-tabs-tab-active {
      color: #333;
      font-weight: bold;

      &:hover {
        color: #333;
      }
    }

    .ant-tabs-ink-bar {
      background-color: #333;
    }

    .ant-form {
      margin-top: 20px;
    }

    .ant-input {
      background-color: #f7f7f7;
      height: 40px;
    }

    .ant-btn {
      width: 100%;
      margin-top: 30px;
    }

    .forget {
      color: #3377ff;
      font-size: 11px;
      cursor: pointer;
    }
  }
}
</style>
