<template>
  <div class="pageHeader">
    <a-page-header
      :title="title"
      class="page-header"
    />
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-left: 0; 
  margin-bottom: 10px; 
  margin-top: -20px;
  border-bottom: 1px solid rgb(235, 237, 240);
}

::v-deep .ant-page-header-heading {
  height: 64px;
}
::v-deep .ant-page-header {
  padding: 0;
}
::v-deep .ant-page-header-heading-title {
  line-height: 74px;
}
</style>
