import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
import {getLocalStorage} from "@/utils/storage"

const prefix = '/api/project'

//获取项目列表
export const getProjectList = (searchForm, page, size) => {
  const data = {
    ...searchForm,
    page,
    size,
    tenant_id: getLocalStorage("user").tenantId
  }
  return fetchApi(URL.GET_PROJECT_LIST, data, 'post', 'data');
};

// 获取当前用户可配置的项目列表
export const getCurrentUserProjectList = (searchForm, page, size) => {
  const data = {
    ...searchForm,
    page,
    size,
    tenant_id: getLocalStorage("user").tenantId
  }
  return fetchApi('/api/project/project/list/roleProjectPage', data, 'post', 'data');
};

//添加项目
export const addProject = data => {
  const submitData = {
    ...data,
    tenant_id: getLocalStorage("user").tenantId
  }
  return fetchApi(URL.ADD_PROJECT, submitData, 'post', 'data');
}

//获取项目详情
export const getProjectDetail = id => {
  return fetchApi(`${URL.GET_PROJECT_DETAIL}/${id}`);
}

//删除项目
export const deleteProject = id => {
  return fetchApi(`${URL.DELETE_PROJECT}/${id}`, {}, "delete")
}

//编辑项目
export const editProject = (data, id) => {
  const submitData = {
    ...data,
    id,
    tenant_id: getLocalStorage("user").tenantId
  }
  return fetchApi(URL.EDIT_PROJECT, submitData, 'put', 'data');
}

/**
 * 查询父级项目
 */
export const getParentProjectList = (id) => {
  const params = {
    projectId: id || ''
  }
  return fetchApi(prefix + '/project/getParentProject', params, 'GET', 'params')
}

/**
 * 查询父级项目
 */
 export const getAllProjectList = (data) => {
  
  return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST,data, 'post', 'data',true);
}


