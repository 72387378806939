import axios from "axios";
import store from "@/store";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from "@/utils/storage";
import router from "@/router"
import { USER_KEY } from "@/config";

// import config from "@/config";

const axiosConfig = {
  // baseURL: config.API_URL,
  // baseURL: "/api",
  timeout: 25000,
};

const http = axios.create(axiosConfig);
// http.defaults.headers.common["Authorization"] = localStorage.getItem("ACCESS_TOKEN");
// http.defaults.headers.common["Authorization"] =

http.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

// Add a request interceptor
http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    store.commit("load/UPDATE_LOADING_STATE", true);
    const userInfoStr = localStorage.getItem("user");
    let userInfo = null;
    if (userInfoStr) userInfo = JSON.parse(userInfoStr);
    if (userInfoStr && userInfo && userInfo.accessToken) {
      if (!config.url.endsWith('/login')) {
        config.headers.Authorization = userInfo.accessToken
      }
    }else {
      
    }
    return config;
  },
  function (error) {
    // Do something with request error
    store.commit("load/UPDATE_LOADING_STATE", true);
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    store.commit("load/UPDATE_LOADING_STATE", false);
    if (response.headers.authorization) {
      const accessToken = JSON.parse(response.headers.authorization).accessToken;
      const user = getLocalStorage(USER_KEY);
      if (user) {
        if (!accessToken.startsWith("Bearer")) {
          user.accessToken = "Bearer " + accessToken;
        } else {
          user.accessToken = accessToken;
        }
        setLocalStorage(USER_KEY, user);
        store.commit("user/UPDATE_USER_INFO", user);
      } else {
        router.replace({ name: "login" });
      }
    }
    if (response.data.code === "A0301") {
      router.replace({ name: "login" });
      store.commit("user/UPDATE_USER_INFO", {});
      removeLocalStorage("user");
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    store.commit("load/UPDATE_LOADING_STATE", false);
    if (error.response.status === 401) {
      // 如果401 清空本地vuex 跳转到login页面
      router.replace({ name: "login" });
      store.commit("user/UPDATE_USER_INFO", {});
      removeLocalStorage("user");
    } else if (error.response.config.url === "/api/dscloud-authority-center/userRole/getMenuList" && error.response.status === 500) {
      // 特殊处理 当访问获取菜单列表报500时跳转登录页面
      router.replace({ name: "login" });
      store.commit("user/UPDATE_USER_INFO", {});
      removeLocalStorage("user");
    } else if (error)
    return Promise.reject(error);
  }
);

function handleParams(url, rawData, rawMethod, useFunc) {
  const method = rawMethod.toUpperCase();
  let data = {};
  if (useFunc === "params") {
    switch (method) {
      case "GET":
        data = { params: rawData };
        break;
      case "POST":
        data = { params: rawData };
        break;
      case "PUT":
      case "PATCH":
      case "DELETE":
        data = { params: rawData };
        break;
      default:
        data = { data: rawData };
        break;
    }
  } else if (useFunc === "body") {
    switch (method) {
      case "GET":
        data = { data: rawData };
        break;
      case "POST":
        data = { data: rawData };
        break;
      case "PUT":
      case "PATCH":
      case "DELETE":
        data = { data: rawData };
        break;
      default:
        data = { data: rawData };
        break;
    }
  }else if (useFunc === 'data') {
    switch (method) {
      case 'GET':
          data = { data: rawData }
          break
      case 'POST':
          data = {data: rawData}
          break
      case 'PUT':
      case 'PATCH':
      case 'DELETE':
          data = { data: rawData }
          break
      default:
          data = { data: rawData }
          break
    }
}
  return {
    url,
    method,
    data,
  };
}

export const fetchApi = (url, rawData = {}, method = "get", useFunc = "params") => {
  const options = handleParams(url, rawData, method, useFunc);

  return new Promise((resolve, reject) => {
    http({
      withCredentials: true,
      url: options.url,
      method: options.method,
      ...options.data,
    }).then((resp) => {
      const res = resp.data;
      resolve(res);
    }),
      (error) => {
        reject(error);
      };
  });
};

export default http;
