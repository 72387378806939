<template>
    <div>
        <div class="displayFlexCenter viewTitle">
            <div class="title">
                会议室预定
                <div class="switchBox">
                    <div class="switchView" @click="switchBut(false)" :class="arrearsYear ? '' : 'switchActive'">本月
                    </div>
                    <div class="switchView" @click="switchBut(true)" :class="arrearsYear ? 'switchActive' : ''">本年</div>
                </div>
            </div>
            <div class="projectTitle">
                项目：<a-tree-select @change="projectChange" :allowClear="true" :replaceFields="fieldName"
                    v-model="projectId" tree-default-expand-all style="width: 200px" :tree-data="projectList"
                    placeholder="请选择项目" :getPopupContainer="(triggerNode) => {
                        return triggerNode.parentNode || document.body;
                    }
                        " />
            </div>
        </div>
        <div class="padding16">
            <div v-show="showEcharts" class="displayFlex">
                <div style="height: 230px; width: 20vw" id="boxpie"></div>
                <div id="maintenance" style="width: 70vw; height: 230px"></div>
            </div>
            <div v-show="!showEcharts" class="placeholderIcon">
                <img src="../../../assets/home/home (21).png" alt="" srcset="" />
            </div>
        </div>
    </div>
</template>

<script>
import { colorList } from '../index';
import * as api from '@/api/home';
import { encode } from '@/utils/base64';
import { getLocalStorage } from '@/utils/storage';
export default {
  props: ['projectList', 'roleId'],
  data() {
    return {
      colorList,
      projectId: null,
      arrearsYear: false,
      showEcharts: false,
      meetingRoomList: [],
      companyList: [],
      myChart: null,
      drawMyChart:null,
      fieldName: {
        value: 'id',
        key: 'id',
        title: 'project_name',
        children: 'child',
      },
    };
  },
  mounted() {
    this.getData();
    // this.initChart()
    // this.drawChart();
  },
  methods: {
    async getData() {
      await api
        .getMeetingInfo({
          roleId: this.roleId,
          projectId: this.projectId,
          type: this.arrearsYear ? '2' : '1',
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.workbenchRoomMeetingInfoResponsesList || res.data.workbenchCompanyMeetingInfoResponsesList) {
              this.showEcharts = true;
            } else {
              this.showEcharts = false;
            }
            this.formData = res.data;
            if (res.data.workbenchRoomMeetingInfoResponsesList) {
              let meetingRoom = [];
              res.data.workbenchRoomMeetingInfoResponsesList.forEach((element) => {
                meetingRoom.push({
                  name: element.meetingRoomName,
                  value: element.reserveCount,
                });
              });
              this.meetingRoomList = meetingRoom;
              this.initChart();
            }
            if (res.data.workbenchCompanyMeetingInfoResponsesList) {
              this.companyList = res.data.workbenchCompanyMeetingInfoResponsesList;
              this.drawChart();
            }
          }
        });
    },
    initChart() {
      var myChart = this.$echarts.init(document.getElementById('boxpie'));
      this.myChart = myChart;
      var echartData = this.meetingRoomList;
      let option = {
        // 标题组件，包含主标题和副标题。
        title: {
          text: '会议室预定时长',
          textStyle: {
            fontWeight: 500,
            fontSize: '14px',
            color: '#222222',
          },
        },
        tooltip: {
          trigger: 'item',
        },
        // 图例组件
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          bottom: '0px',
          width: '100%',
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: '55%',
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: echartData,
          },
        ],
      };
      myChart.setOption(option, true);
      this.myChart.on('click', () => {
        this.jumpRouter()
      });
    },
    drawChart() {
      //2. 基于准备好的dom，初始化echarts实例
      //此处的意思就是，对 demo 元素 进行图表初始化的相关操作
      var myChart = this.$echarts.init(document.getElementById('maintenance'));
      this.drawMyChart=myChart
      //3. 指定图表的配置项和数据
      //该处就是图表内容，在官网的示例里面，要复制过来到项目里面的也是这一块内容
      let nameList = [];
      let valList = [];
      this.companyList.forEach((item) => {
        nameList.push(item.companyName);
        valList.push(item.appointmentCount);
      });
      var option = {
        title: {
          text: '预订公司TOP10',
          textStyle: {
            fontWeight: 500,
            fontSize: '14px',
            color: '#222222',
          },
        },
        xAxis: {
          data: nameList,
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              // 如果标签长度超过4，将剩余的文字替换为省略号
              if (value.length > 4) {
                return value.slice(0, 4) + '...';
              } else {
                return value;
              }
            },
          },
        },
        yAxis: {
          type: 'value',
        },
        grid: {
          bottom: '10%',
        },
        series: [
          {
            type: 'bar',
            barWidth: '20px',
            itemStyle: { normal: { color: '#409EFF' } },
            data: valList,
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      };
      //4.使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.drawMyChart.on('click', () => {
        this.jumpRouter()
      });
    },
    //跳转页面地址
    jumpRouter() {
        const user = getLocalStorage('user');
        const encryptToken = encode(user.commonToken);
        let path=process.env.NODE_ENV === 'production'?'https://yuanqu.innoecos.cn':'https://yuanqu-sit.innoecos.cn'
        window.open(
            `${path}/apps/dscloud-webbv/subscribeList?ck=${encryptToken}&dir=0000000000004400&from=iyou`,
            '_blank'
          );
    },
    switchBut(val) {
      if (val == this.arrearsYear) {
        return;
      }
      this.arrearsYear = val;
      this.getData();
    },
    projectChange() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.displayFlexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.displayFlex {
    display: flex;
}

.viewTitle {
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    margin-top: 40px;
    // border-top: 1px solid #EEEEEE;
    // padding-top: 20px;

    .title {
        font-weight: 500;
        font-size: 18px;
        color: #222222;
        display: flex;
        align-items: center;
    }

    .projectTitle {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }
}

.marginLeft4 {
    margin-left: 4px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginLeft80 {
    margin-left: 80px;
}

.padding16 {
    padding: 16px 0px;
    align-items: center;
}

.echartBox {
    width: 330px;
    height: 230px;
}

.graphsBox {
    height: inherit;
    display: flex;
    align-items: center;

    .graphsTitleView {
        background: #fafafa;
        flex: 1;
        padding: 20px 0px 20px 20px;

        .graphsTitleViewTitle {
            display: flex;
            align-items: center;

            .title {
                margin-left: 4px;
            }
        }
    }
}

.flex1 {
    flex: 1;
}

.graphsTitle {
    font-size: 14px;
    color: #222222;
    font-weight: 500;
}

.iconImg {
    height: 20px;
    width: 20px;
}

.switchBox {
    width: 108px;
    height: 28px;
    background: #f2f3f8;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 20px 16px 24px;

    .switchView {
        font-weight: 400;
        font-size: 12px;
        height: 22px;
        line-height: 22px;
        width: 48px;
        text-align: center;
        cursor: pointer;
    }

    .switchActive {
        color: #1664ff;
        background: #ffffff;
        border-radius: 2px;
    }
}

.placeholderIcon {
    display: flex;
    justify-content: center;
    min-height: 230px;

    img {
        width: 300px;
        margin-top: 30px;
    }
}
</style>
