<template>
  <div class="side-bar">
    <logo></logo>
    <el-menu
      class="side-menu"
      :default-active="currentPage"
    >
      <sub-menu :subMenu="subMenu"></sub-menu>
      <!-- <qr-code></qr-code> -->
    </el-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Logo from "./Logo.vue";
// import QrCode from "./QrCode.vue";
import SubMenu from "./SubMenu.vue";
export default {
  components: { Logo, SubMenu },
  computed: {
    ...mapState({
      subMenu: (state) => state.permission.subMenu,
      currentPage: (state) => state.permission.currentPage,
      tabsList: (state) => state.tabs.tabsList,
    }),
  },
  methods: {
    handleSelect(item) {
      this.$store.commit("tabs/UPDATE_TABS_LIST", item);
      this.$actions.setGlobalState({ tabsList: this.tabsList });
      this.$router.push(item.path);
    },
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/ .el-menu {
//   border-right: none;
// }
.side-bar {
  width: 240px;
  height: 100%;
}
.side-menu {
  width: 240px;
  height: calc(100% - 60px);
}
 .el-menu-item, .el-submenu__title{
        height: 40px;
    line-height: 40px;
}
</style>
