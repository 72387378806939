<!--
 * @Author: lizhijie429
 * @Date: 2021-08-19 11:16:08
 * @LastEditors: lizhijie429
 * @LastEditTime: 2021-08-19 11:18:22
 * @Description:
-->
<template>
  <div class="logo">
    <img :src="logoImg" alt="" class="logoImag">
  </div>
</template>

<script>
import logo from '@/assets/logo.png'
import kc_login from '@/assets/images/platform-icon.png'
export default {
  name: "Logo",
  computed: {
    logoImg() {
      const flag = window.location.href.includes('kcjt')
      return flag ? kc_login : logo
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  line-height: 64px;
  background: #002140;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  padding-top: 10px;
}
.logoImag {
  width: 100%;
  //height: 100%;
  //object-fit:cover;
}
</style>
