<template>
  <div class="main">
    <a-spin :spinning="isLoading" size="large">
      <layout>
        <router-view v-if="isMain" class="height-abs"></router-view>
        <div v-else id="subapp-viewport" class="app-view-box"></div>
      </layout>
    </a-spin>
  </div>
</template>

<script>
import Layout from "@/layout/index.vue";
import { registerApps } from "@/qiankun/index";
import { mapState } from "vuex";
export default {
  data() {
    return {
      isMain: false,
    };
  },
  components: {
    Layout,
  },
  watch: {
    $route(to) {
      this.isMainPage(to);
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  mounted() {
    this.isMainPage(this.$route);
    if (!window.qiankunStarted) {
      window.qiankunStarted = true;
      registerApps();
    }
  },
  methods: {
    isMainPage(value) {
      if (value.meta.moduleName === "main") {
        this.isMain = true;
      } else {
        this.isMain = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
  height: 100vh;
}
</style>
