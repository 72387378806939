<template>
    <div>
        <div class="displayFlexCenter viewTitle">
            <div class="title">报修工单
                <div class="switchBox">
                    <div class="switchView " @click="switchBut(false)" :class="arrearsYear ? '' : 'switchActive'">
                        本月</div>
                    <div class="switchView" @click="switchBut(true)" :class="arrearsYear ? 'switchActive' : ''">本年
                    </div>
                </div>
            </div>
            <div class="projectTitle">
                项目：<a-tree-select @change="projectChange" :allowClear="true" :replaceFields="fieldName"
                    v-model="projectId" tree-default-expand-all style="width: 200px" :tree-data="projectList"
                    placeholder="请选择项目" :getPopupContainer="(triggerNode) => {
                        return triggerNode.parentNode || document.body;
                    }" />
            </div>
        </div>
        <div class="displayFlex padding16">
            <!-- <div style="height: 230px;width: 330px;" ref="boxpie"></div> -->
            <div style="height: 230px;width: 20vw;" id="workOrderPie"></div>
            <div id="workOrderCategory" style="width: 70vw;height:230px;"></div>
        </div>
    </div>
</template>

<script>
import { colorList,jumpAppointRouter } from '../index'
import * as api from "@/api/home";
export default {
    props: ['projectList', 'roleId'],
    data() {
        return {
            colorList,
            projectId: null,
            arrearsYear: false,
            workOrderChart:null,//工单处理状态
            workOrderTypeChart:null,//报修类型趋势
            fieldName: {
                value: 'id',
                key: 'id',
                title: 'project_name',
                children: 'child',
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            await api.getWorkOrderInfo({
                roleId: this.roleId,
                projectId: this.projectId,
                type: this.arrearsYear ? '2' : '1'

            }).then((res) => {
                if (res.code == 200) {
                    this.formData = res.data
                    this.initChart()
                    this.drawChart()
                }
            });
        },
        initChart() {
            var myChart = this.$echarts.init(document.getElementById('workOrderPie'));
            this.workOrderChart=myChart
            var echartData = [
                {
                    name: '待处理',
                    value: this.formData.pendingCount || 0,
                },
                {
                    name: '处理中',
                    value: this.formData.processingCount || 0,
                }, {
                    name: '暂缓中',
                    value: this.formData.suspendedCount || 0,
                },
                {
                    name: '已完成',
                    value: this.formData.completedCount || 0,
                }
            ]
            let option = {
                // 标题组件，包含主标题和副标题。
                title: {
                    text: '工单处理状态',
                    textStyle: {
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#222222'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                // 图例组件
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    bottom: '0px',
                    width: '100%',
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: '55%',
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false
                        },
                        data: echartData
                    }
                ],
            };
            myChart.setOption(option, true)
            this.workOrderChart.on('click',()=>{
                jumpAppointRouter('park','/park/workorderAdmin/workorder')
            })
        },
        drawChart() {
            //2. 基于准备好的dom，初始化echarts实例
            //此处的意思就是，对 demo 元素 进行图表初始化的相关操作
            var myChart = this.$echarts.init(document.getElementById('workOrderCategory'));
            this.workOrderTypeChart=myChart
            //3. 指定图表的配置项和数据
            //该处就是图表内容，在官网的示例里面，要复制过来到项目里面的也是这一块内容
            var option = {
                title: {
                    text: '报修类型趋势',
                    textStyle: {
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#222222'
                    }
                },
                xAxis: {
                    data: ["空间主体", "水路管道", "电器维修", "其他"]
                },
                yAxis: {
                    type: 'value'
                },
                grid: {
                    bottom: '10%'
                },
                series: [{
                    type: 'bar',
                    barWidth: '20px',
                    itemStyle: { normal: { color: '#409EFF' } },
                    data: [this.formData.spaceSubjectCount || 0, this.formData.waterPipeCount || 0, this.formData.electricalMaintenanceCount || 0, this.formData.otherCount || 0,],
                    label: {
                        show: true,
                        position: 'top',
                    }
                }]
            };
            //4.使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
            this.workOrderTypeChart.on('click',()=>{
                jumpAppointRouter('park','/park/workorderAdmin/workorder')
            })

        },
        switchBut(val) {
            if (val == this.arrearsYear) {
                return
            }
            this.arrearsYear = val
            this.getData()

        },
        projectChange() {
            this.getData()
        },
    }

}
</script>

<style lang="scss" scoped>
.displayFlexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.displayFlex {
    display: flex;
}

.viewTitle {
    padding-bottom: 16px;
    border-bottom: 1px solid #EEEEEE;
    margin-top: 40px;
    // border-top: 1px solid #EEEEEE;
    // padding-top: 20px;

    .title {
        font-weight: 500;
        font-size: 18px;
        color: #222222;
        display: flex;
        align-items: center;
    }

    .projectTitle {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }
}



.marginLeft4 {
    margin-left: 4px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginLeft80 {
    margin-left: 80px;
}

.padding16 {
    padding: 16px 0px;
    align-items: center;
}

.housingResources {
    padding: 16px 0px;
    align-items: center;
}

.graphsBox {
    height: inherit;
    display: flex;
    align-items: center;

    .graphsTitleView {
        background: #FAFAFA;
        flex: 1;
        padding: 20px 0px 20px 20px;

        .graphsTitleViewTitle {
            display: flex;
            align-items: center;

            .title {
                margin-left: 4px;
            }
        }

        .graphsTitleViewSpace {
            margin-top: 16px;

            .number {
                font-size: 22px;
                color: #222222;
                font-weight: 700;
            }

            .unit {
                font-size: 12px;
                color: #555555;
                margin-left: 4px;
            }
        }
    }
}

.flex1 {
    flex: 1;
}

.graphsTitle {
    font-size: 14px;
    color: #222222;
    font-weight: 500;
}

.iconImg {
    height: 20px;
    width: 20px;
}

.switchBox {
    width: 108px;
    height: 28px;
    background: #F2F3F8;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 20px 16px 24px;

    .switchView {
        font-weight: 400;
        font-size: 12px;
        height: 22px;
        line-height: 22px;
        width: 48px;
        text-align: center;
        cursor: pointer;
    }

    .switchActive {
        color: #1664FF;
        background: #FFFFFF;
        border-radius: 2px;
    }
}
</style>