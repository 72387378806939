<template>
    <div>
        <div class="displayFlexCenter viewTitle">
            <div class="title">合同回款</div>
            <div class="projectTitle">
                项目：<a-tree-select @change="projectChange" :allowClear="true" :replaceFields="fieldName"
                    v-model="projectId" tree-default-expand-all style="width: 200px" :tree-data="projectList"
                    placeholder="请选择项目" :getPopupContainer="(triggerNode) => {
                        return triggerNode.parentNode || document.body;
                    }" />
            </div>
        </div>
        <div class="displayFlex housingResources">
            <div class="flex1">
                <div class="graphsBox">
                    <div class="displayFlex" style="flex: auto;align-items: center;">
                        <div class="graphsTitleView" @click="jumpRouter('asset', '/asset/contractManagement/leaseContract')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (6).png" alt="" srcset="">
                                <span class="title">年度合同金额</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ formData.yearContractAmountTotal }}</span>
                                <span class="unit">万元</span>
                                <span class="number marginLeft4">/{{ formData.yearPlanContractAmountTotal }}</span>
                                <span class="unit">万元</span>
                            </div>
                            <div class="placeholderHeight"></div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset', '/asset/contractManagement/leaseContract')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (7).png" alt="" srcset="">
                                <span class="title">签约合同</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number" v-if="contractCheckYear">{{ formData.yearContractTotal }}</span>
                                <span class="number" v-else>{{ formData.monthContractTotal }}</span>
                                <span class="unit">个</span>
                            </div>
                            <div class="displayFlexEnd">
                                <div class="switchBox">
                                    <div class="switchView " @click.stop="contractCheckYear = false"
                                        :class="contractCheckYear ? '' : 'switchActive'">
                                        本月</div>
                                    <div class="switchView" @click.stop="contractCheckYear = true"
                                        :class="contractCheckYear ? 'switchActive' : ''">本年
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="boder"></div>

                        <div class="graphsTitleView" @click="jumpRouter('asset', '/asset/contractManagement/leaseContract')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (2).png" alt="" srcset="">
                                <span class="title">即将到期合同</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ formData.beToExpireContractTotal }}</span>
                                <span class="unit">个</span>
                            </div>
                            <div class="placeholderHeight"></div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset', '/asset/customerManagement')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (7).png" alt="" srcset="">
                                <span class="title">欠租客户</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ formData.backRentCustomerTotal }}</span>
                                <span class="unit">个</span>
                            </div>
                            <div class="placeholderHeight"></div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset', '/asset/revenueManagement/billReturnedRecord')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (1).png" alt="" srcset="">
                                <span class="title">回款金额</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number" v-if="paymentCollectionYear">{{ formData.yearRepaymentAmountTotal
                                    }}</span>
                                <span class="number" v-else>{{ formData.monthRepaymentAmountTotal }}</span>
                                <span class="unit">万元</span>
                            </div>
                            <div class="displayFlexEnd">
                                <div class="switchBox">
                                    <div class="switchView " @click.stop="paymentCollectionYear = false"
                                        :class="paymentCollectionYear ? '' : 'switchActive'">
                                        本月</div>
                                    <div class="switchView" @click.stop="paymentCollectionYear = true"
                                        :class="paymentCollectionYear ? 'switchActive' : ''">本年
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset', '/asset/revenueManagement/billManagement')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (7).png" alt="" srcset="">
                                <span class="title">欠租金额</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number" v-if="arrearsYear">{{ formData.yearBackRentAmountTotal }}</span>
                                <span class="number" v-else>{{ formData.monthBackRentAmountTotal }}</span>
                                <span class="unit">万元</span>
                            </div>
                            <div class="displayFlexEnd">
                                <div class="switchBox">
                                    <div class="switchView " @click.stop="arrearsYear = false"
                                        :class="arrearsYear ? '' : 'switchActive'">
                                        本月</div>
                                    <div class="switchView" @click.stop="arrearsYear = true"
                                        :class="arrearsYear ? 'switchActive' : ''">本年
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { colorList,jumpAppointRouter } from '../index'
import * as api from "@/api/home";
export default {
    props: ['projectList', 'roleId'],
    data() {
        return {
            colorList,
            projectId: null,
            contractCheckYear: false,//签约合同
            paymentCollectionYear: false,//回款金额
            arrearsYear: false,//欠款金额
            formData: {},
            fieldName: {
                value: 'id',
                key: 'id',
                title: 'project_name',
                children: 'child',
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            await api.getPaymentCollection({ projectId: this.projectId, roleId: this.roleId }).then((res) => {
                if (res.code == 200) {
                    this.formData = res.data
                }
            });
        },
        projectChange() {
            this.getData()
        },
        //跳转页面地址
        jumpRouter(moduleName, path){
            jumpAppointRouter(moduleName,path)
        }

    }

}
</script>

<style lang="scss" scoped>
.displayFlexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.displayFlex {
    display: flex;
}

.viewTitle {
    padding-bottom: 16px;
    border-bottom: 1px solid #EEEEEE;
    margin-top: 40px;
    // border-top: 1px solid #EEEEEE;
    // padding-top: 20px;

    .title {
        font-weight: 500;
        font-size: 18px;
        color: #222222;
    }

    .projectTitle {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }
}



.marginLeft4 {
    margin-left: 4px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginLeft80 {
    margin-left: 80px;
}

.housingResources {
    padding: 16px 0px;
    align-items: center;
}

.graphsBox {
    height: inherit;
    display: flex;
    align-items: center;

    .graphsTitleView {
        background: #FAFAFA;
        flex: 1;
        padding: 20px 0px 0px 20px;
        cursor: pointer;

        .graphsTitleViewTitle {
            display: flex;
            align-items: center;

            .title {
                margin-left: 4px;
            }
        }

        .graphsTitleViewSpace {
            margin-top: 16px;

            .number {
                font-size: 22px;
                color: #222222;
                font-weight: 700;
            }

            .unit {
                font-size: 12px;
                color: #555555;
                margin-left: 4px;
            }
        }

        .placeholderHeight {
            height: 28px;
            margin-top: 12px;
            margin-bottom: 16px;
        }
    }
}

.flex1 {
    flex: 1;
}

.graphsTitle {
    font-size: 14px;
    color: #222222;
    font-weight: 500;
}

.iconImg {
    height: 20px;
    width: 20px;
}

.boder {
    height: 60px;
    border-left: 1px solid #EEEEEE;
    margin-left: 40px;
    margin-right: 40px;
}

.displayFlexEnd {
    display: flex;
    justify-content: flex-end;
}

.switchBox {
    width: 108px;
    height: 28px;
    background: #F2F3F8;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 16px;
    margin-right: 20px;

    .switchView {
        font-weight: 400;
        font-size: 12px;
        height: 22px;
        line-height: 22px;
        width: 48px;
        text-align: center;
        cursor: pointer;
    }

    .switchActive {
        color: #1664FF;
        background: #FFFFFF;
        border-radius: 2px;
    }
}
</style>