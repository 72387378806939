let Base64 = require('js-base64').Base64;
// 分隔符
const segmentation = "iygzt-dsbzyjs";

/**
 * 加密
 * @param {*} clearText 明文
 * @returns 密文
 */
export const encode = clearText => Base64.encode(clearText);


/**
 * 解密
 * @param {*} cipherText 密文
 * @returns 明文
 */
export const decode = cipherText => Base64.decode(cipherText);

/**
 * 外部链接加密
 * @param {*} clearText 链接明文
 * @returns 链接密文
 */
export const linkEncode = (clearText, id, linkType) => {
  const segmentation = "iygzt-dsbzyjs";
  const tidyText = `${clearText}${segmentation}${id}${segmentation}${linkType}`;
  return encode(tidyText);
}

/**
 * 外部链接解密
 * @param {*} cipherText 链接密文
 * @returns 链接明文
 */
export const linkDecode = cipherText => {
  const clearText = Base64.decode(cipherText);
  const tidyData = clearText.split(segmentation);
  return {
    linkUrl: tidyData[0],
    linkId: tidyData[1],
    linkType: tidyData[2],
  };
}

export default {
  encode,
  decode,
  linkEncode,
  linkDecode
}