import router from "@/router/index";
import { qiankunActions } from "@/qiankun/index";

const tabs = {
  namespaced: true,
  state: () => ({
    tabsList: [],
  }),
  mutations: {
    // 设置tabs数据
    SET_TABS_LIST(state, payload) {
      if (payload[0] === false) {
        state.tabsList = [];
      }else {
        state.tabsList = payload;
      }

    },
    // 新增tabs
    UPDATE_TABS_LIST(state, payload) {
      if (state.tabsList.length < 10) {
        const hasTabsItem = state.tabsList.filter((item) => {
          return item.menuName === payload.menuName;
        });
        if (!hasTabsItem.length) {
          if (payload.menuName) {
            state.tabsList.push(payload);
          }
        }
        let currentQuery = sessionStorage.getItem("currentQuery");
        if (currentQuery) {
          currentQuery = JSON.parse(currentQuery);
          router.push({
            path: payload.path,
            query: {
              ...currentQuery,
            },
          });
        } else {
          router.push({ path: payload.path });
        }
      }
    },
  },
  actions: {
    // 删除最后一个tabs标签
    REMOVE_LAST_TAB({ state }, payload) {
      if (state.tabsList.length > 1) {
        // 第一步、删除
        state.tabsList.forEach((item, index) => {

          if (item.menuPath === payload.menuPath) {
            state.tabsList.splice(index, 1);
          }
        });
        // 第二步、获取最后一个tabs的name跳转路由
        let index = state.tabsList.length - 1;
        let lastTabsItem = state.tabsList[index];
        router.push({ path: lastTabsItem.menuPath });
      }
      qiankunActions.setGlobalState({ tabsList: state.tabsList });
    },
    // 删除除了第一个跟最后一个以外得任何一个tab标签
    REMOVE_ANY_TAB({ state }, payload) {
      const { tabsItem, indexOf } = payload;
      let tabsListLength = state.tabsList.length;
      if (state.tabsList.length > 1 && indexOf < tabsListLength) {
        const currentPage = sessionStorage.getItem("currentPage");
        if (payload.tabsItem.menuPath === currentPage) {
          // 第一步：执行删除操作
          state.tabsList.forEach((item, index) => {
            if (item.menuPath === tabsItem.menuPath) {
              state.tabsList.splice(index, 1);
            }
          });
          // 第二步：获取删除后面的tabs的name并跳转路由
          let nextTabsItem = state.tabsList[indexOf];
          router.push({ path: nextTabsItem.menuPath });
        } else {
          state.tabsList.forEach((item, index) => {
            if (item.menuPath === tabsItem.menuPath) {
              state.tabsList.splice(index, 1);
            }
          });
        }
        qiankunActions.setGlobalState({ tabsList: state.tabsList });
      }
    },
  },
};
export default tabs;
