/**按钮类型配置
 * DS_ADD: 添加
 * DS_SEARCH: 查询
 * DS_EXPORT: 导出
 * DS_UPLOAD: 批量上传
 * DS_RESET: 重置
 * DS_SAVE: 保存
 * DS_SUBMIT: 提交
 * DS_BACK: 返回
 * DS_DEFAULT: 项目默认主按钮(如果选择该样式, 必须要传title)
 * DS_CANCEL: 项目默认取消按钮
 */
export default {
  DS_ADD: "add",
  DS_SEARCH: "search",
  DS_EXPORT: "export",
  DS_UPLOAD: "batchUpload",
  DS_RESET: "reset",
  DS_SAVE: "save",
  DS_SUBMIT: "submit",
  DS_BACK: "back",
  DS_DEFAULT: "default",
  DS_CANCEL: "cancel",
}