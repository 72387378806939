<template>
  <div class="home">
    <div v-if="!showFlag">
      <needMatter v-if="includesData(0)" :projectList="projectList" :roleId="roleId"></needMatter>
      <housingResources v-if="includesData(1)" :projectList="projectList" :roleId="roleId" class="maginTop10">
      </housingResources>
      <Clue v-if="includesData(2)" :projectList="projectList" class="maginTop10" :roleId="roleId"></Clue>
      <PaymentCollection v-if="includesData(3)" :projectList="projectList" class="maginTop10" :roleId="roleId">
      </PaymentCollection>
      <WorkOrder v-if="includesData(4)" :projectList="projectList" class="maginTop10" :roleId="roleId"></WorkOrder>
      <MeetingRoom v-if="includesData(5)" class="maginTop10" :projectList="projectList" :roleId="roleId"></MeetingRoom>
      <EnterpriseOperations :menuList="menuList" :projectList="projectList" class="maginTop10" :roleId="roleId">
      </EnterpriseOperations>
    </div>
    <div v-if="showFlag" class="welcomeContainer">
      <img v-if="flag" src="@/assets/images/kc-empty-icon.jpg" class="kc-welcome-logo" />
      <img v-else src="@/assets/images/welcome.png" class="welcomeLogo" />
      <div v-show="!flag" class="welcomeContent">欢迎使用i友工作台</div>
    </div>
  </div>
</template>

<script>
import NeedMatter from './components/needMatter.vue' //待办事项
import HousingResources from './components/housingResources.vue'//房源招商
import Clue from './components/clue.vue'//线索客户
import WorkOrder from './components/workOrder.vue'//线索客户
import MeetingRoom from './components/meetingRoom.vue'//线索客户
import EnterpriseOperations from './components/enterpriseOperations.vue'//企业运营
import PaymentCollection from './components/paymentCollection.vue'//企业运营
import store from "@/store";
import * as api from "@/api/home";
export default {
  name: "Home",
  components: { NeedMatter, HousingResources, Clue, WorkOrder, MeetingRoom, EnterpriseOperations, PaymentCollection },
  data() {
    return {
      flag: false,
      showFlag: true,
      roleId: '',
      projectList: [],
      menuList: []
    }
  },
  created() {

    this.getProjectList()
    this.getHomeData()
  },
  mounted() {
    // let menuList=store.state.permission.menuList[0].menuList[0].roleId
    // menuList.forEach(element => {
    //   console.log(element);
    // });
  },
  methods: {
    // 获取项目下拉值
    async getProjectList() {
      await api.getProjectList().then((res) => {
        if (res.code === '200') {
          this.projectList = res.data;
        }
      });
    },
    async getHomeData() {
      api.getWorkBenchModule().then((res) => {
        if (res.code == 200 && res.data) {
          this.showFlag = false
          let list = []
          let menuList = []
          res.data.forEach(element => {
            list.push(element.roleId)
            menuList.push(element.type)
          });
          this.menuList = menuList
          this.roleId = list.join(',')
        } else {
          this.flag = window.location.href.includes('kcjt')
          this.showFlag = true
        }

      })
    },
    includesData(val) {
      return this.menuList.includes(val)
    },
  }
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;

  .welcomeContainer {
    position: absolute;
    width: 207px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .welcomeLogo {
      width: 100%;
      height: 160px;
    }

    .kc-welcome-logo {
      //width: 100%;
    }

    .welcomeContent {
      font-size: 12px;
      height: 40px;
      line-height: 40px;
      color: #999999;
    }
  }
}

::v-deep .el-main {
  padding: 0px !important;
}
</style>