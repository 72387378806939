<template>
    <div>
        <div class="displayFlexCenter viewTitle">
            <div class="title">房源招商</div>
            <div class="projectTitle">
                项目：<a-tree-select @change="projectChange" :allowClear="true" :replaceFields="fieldName"
                    v-model="projectId" tree-default-expand-all style="width: 200px" :tree-data="projectList"
                    placeholder="请选择项目" :getPopupContainer="(triggerNode) => {
                        return triggerNode.parentNode || document.body;
                    }" />
            </div>
        </div>
        <div class="displayFlex housingResources">
            <div style="height: 230px;width: 18vw;" id="housingResourcesPie"></div>
            <div class="flex1 marginLeft80" style="height: 230px;">
                <div class="graphsTitle">关键数据</div>
                <div class="graphsBox">
                    <div class="displayFlex" style="flex: auto;">
                        <div class="graphsTitleView" @click="jumpRouter('operation','/operation/lease/housingResources')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (13).png" alt="" srcset="">
                                <span class="title">在租房源</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ formData.rentingHouseCount || 0 }}</span>
                                <span class="unit">间</span>
                            </div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset','/asset/parkAssetManagement/assetData')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (19).png" alt="" srcset="">
                                <span class="title">出租面积率</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ formData.rentalAreaRate || 0 }}</span>
                                <span class="unit">%</span>
                                <span class="number marginLeft4" v-if="formData.rentalAreaRateYearPlan">/ {{ formData.rentalAreaRateYearPlan || 0 }}</span>
                                <span class="unit" v-if="formData.rentalAreaRateYearPlan">%</span>
                            </div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('operation','/operation/lease/housingResources')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (20).png" alt="" srcset="">
                                <span class="title">当前出租均价</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ formData.currentRentalPrice || 0 }}</span>
                                <span class="unit">元/㎡/天</span>
                            </div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset','/asset/parkAssetManagement/assetData')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (18).png" alt="" srcset="">
                                <span class="title">出租面积</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ formData.rentalTotalArea || 0 }}</span>
                                <span class="unit">㎡</span>
                                <span class="number marginLeft4" v-if="formData.rentalTotalAreaYearPlan">/{{ formData.rentalTotalAreaYearPlan || 0 }}</span>
                                <span class="unit"  v-if="formData.rentalTotalAreaYearPlan">㎡</span>
                            </div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset','/asset/parkAssetManagement/assetData')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (4).png" alt="" srcset="">
                                <span class="title">空置面积</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ formData.vacantArea || 0 }}</span>
                                <span class="unit">㎡</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { colorList,jumpAppointRouter } from '../index'
import * as api from "@/api/home";
export default {
    props: ['projectList', 'roleId'],
    data() {
        return {
            colorList,
            projectId: null,
            fieldName: {
                value: 'id',
                key: 'id',
                title: 'project_name',
                children: 'child',
            },
            formData: {},
            myChart:null,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            await api.getHouseRecruitmentInfo({
                roleId: this.roleId,
                projectId:this.projectId
            }).then((res) => {
                if (res.code == 200) {
                    this.formData = res.data
                    this.initChart();
                }
            });
        },
        initChart() {
            var myChart = this.$echarts.init(document.getElementById('housingResourcesPie'));
            this.myChart=myChart
            var echartData = [
                {
                    name: '办公室',
                    value: this.formData.officeCount,
                },
                {
                    name: '厂房',
                    value: this.formData.plantCount,
                }, {
                    name: '其他',
                    value: this.formData.restsCount,
                },
            ]
            let option = {
                // 标题组件，包含主标题和副标题。
                title: {
                    text: '在租房源类型分布',
                    textStyle: {
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#222222'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                // 图例组件
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    bottom: '0px',
                    width: '100%',
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: '55%',
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false
                        },
                        data: echartData
                    }
                ],
            };
            myChart.setOption(option, true)
            this.myChart.on('click',()=>{
                jumpAppointRouter('operation','/operation/lease/housingResources')
            })
        },
        projectChange(){
            this.getData()
        },
        //跳转页面地址
        jumpRouter(moduleName, path){
            jumpAppointRouter(moduleName,path)
        }
    }

}
</script>

<style lang="scss" scoped>
.displayFlexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.displayFlex {
    display: flex;
}

.viewTitle {
    padding-bottom: 16px;
    border-bottom: 1px solid #EEEEEE;
    margin-top: 40px;
    // border-top: 1px solid #EEEEEE;
    // padding-top: 20px;

    .title {
        font-weight: 500;
        font-size: 18px;
        color: #222222;
    }

    .projectTitle {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }
}



.marginLeft4 {
    margin-left: 4px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginLeft80 {
    margin-left: 80px;
}

.housingResources {
    padding: 16px 0px;
    align-items: center;
}

.echartBox {
    width: 330px;
    height: 230px;
}

.graphsBox {
    height: inherit;
    display: flex;
    align-items: center;

    .graphsTitleView {
        background: #FAFAFA;
        flex: 1;
        padding: 20px 0px 20px 20px;
        cursor: pointer;

        .graphsTitleViewTitle {
            display: flex;
            align-items: center;

            .title {
                margin-left: 4px;
            }
        }

        .graphsTitleViewSpace {
            margin-top: 16px;

            .number {
                font-size: 22px;
                color: #222222;
                font-weight: 700;
            }

            .unit {
                font-size: 12px;
                color: #555555;
                margin-left: 4px;
            }
        }
    }
}

.flex1 {
    flex: 1;
}

.graphsTitle {
    font-size: 14px;
    color: #222222;
    font-weight: 500;
}

.iconImg {
    height: 20px;
    width: 20px;
}
</style>