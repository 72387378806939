import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

//添加菜单
export const addMenu = (data) => {
  return fetchApi(URL.ADD_MENU, data, "post", "data");
};

//获取左侧菜单
export const getLeftMenu = () => {
  return fetchApi(URL.GET_LEFT_MENU);
}

//获取菜单列表
export const getMenuList = (platformId, pageNum, pageSize) => {
  const data = {
    platformId,
    pageNum,
    pageSize
  }
  return fetchApi(URL.GET_MENU_LIST, data, "post", "data")
}

// 租户管理查询菜单列表
export const getMenus = (platformId, pageNum, pageSize) => {
  const data = {
    platformId,
    pageNum,
    pageSize
  }
  return fetchApi('/api/dscloud-authority-center/userRole/findMenuByPlatformId', data, "post", "data")
}

// 删除菜单
export const deleteMenu = (id) => {
  return fetchApi(`${URL.DELETE_MENU}/${id}`, {}, "delete")
}

// 编辑菜单
export const editMenu = (data) => {
  return fetchApi(URL.EDIT_MENU, data, "post", "data");
};
