<template>
    <div>
        <div class="displayFlexCenter viewTitle">
            <div class="title">线索客户
                <div class="switchBox">
                    <div class="switchView " @click="switchBut(false)" :class="arrearsYear ? '' : 'switchActive'">
                        本月</div>
                    <div class="switchView" @click="switchBut(true)" :class="arrearsYear ? 'switchActive' : ''">本年
                    </div>
                </div>

            </div>
            <div class="projectTitle">
                项目：<a-tree-select @change="projectChange" :allowClear="true" :replaceFields="fieldName"
                    v-model="projectId" tree-default-expand-all style="width: 200px" :tree-data="projectList"
                    placeholder="请选择项目" :getPopupContainer="(triggerNode) => {
                        return triggerNode.parentNode || document.body;
                    }" />
            </div>
        </div>
        <div class="displayFlex housingResources">
            <div style="height: 230px;width: 20vw;" id="clueTypePie"></div>
            <div style="height: 230px;width: 20vw;margin-left: 50px;" id="customTypePie"></div>
            <!-- <div style="height: 230px;width: 330px;" class="echartBox" ref="boxType"></div> -->
            <div class="flex1 marginLeft80" style="height: 230px;">
                <div class="graphsTitle">关键数据</div>
                <div class="graphsBox">
                    <div class="displayFlex" style="flex: auto;">
                        <div class="graphsTitleView" @click="jumpRouter('asset','/asset/clueManagement')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (3).png" alt="" srcset="">
                                <span class="title">新增线索</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ clueFormData.increasedClueCount }}</span>
                                <span class="unit">个</span>
                            </div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset','/asset/customerManagement')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (8).png" alt="" srcset="">
                                <span class="title">新增客户</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ customFormData.increasedCustomerCount }}</span>
                                <span class="unit">个</span>
                            </div>
                        </div>
                        <div class="graphsTitleView marginLeft20" @click="jumpRouter('asset','/asset/customerManagement')">
                            <div class="graphsTitleViewTitle">
                                <img class="iconImg" src="../../../assets/home/home (14).png" alt="" srcset="">
                                <span class="title">转成交客户</span>
                            </div>
                            <div class="graphsTitleViewSpace">
                                <span class="number">{{ customFormData.increasedSignCustomerCount }}</span>
                                <span class="unit">个</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { colorList,jumpAppointRouter } from '../index'
import * as api from "@/api/home";
export default {
    props: ['projectList', 'roleId'],
    data() {
        return {
            colorList,
            clueFormData: {},
            customFormData:{},
            projectId: null,
            arrearsYear: false,
            clueList:[],//线索类型分布
            clueChart:null,
            customList:[],//客户类型分布
            customChart:null,
            fieldName: {
                value: 'id',
                key: 'id',
                title: 'project_name',
                children: 'child',
            },
        }
    },
    mounted() {
        this.getClueData()
        this.getCustomData()
        // this.initChart()//线索类型分布
        // this.customTypePie()//客户类型分布

    },
    methods: {
        switchBut(val) {
            if (val == this.arrearsYear) {
                return
            }
            this.arrearsYear = val
            this.getClueData()
            this.getCustomData()
            
        },
        projectChange() {
            this.getClueData()
            this.getCustomData()
        },
        async getClueData() {
            await api.getClueMessage({
                periodType: this.arrearsYear?'20':'10',
                projectId:this.projectId
            }).then((res) => {
                if (res.code == 200) {
                    let clueList=[]
                    res.data.increasedClueInfoDistributionList.forEach(element => {
                        clueList.push({
                            name:element.channelTypeName,
                            value:element.clueCount,
                        })
                    });
                    this.clueList=clueList
                    this.clueFormData = res.data
                    this.initChart();
                }
            });
        },
        async getCustomData() {
            await api.getCustomMessage({
                periodType: this.arrearsYear?'20':'10',
                projectId:this.projectId
            }).then((res) => {
                if (res.code == 200) {
                    let customList=[]
                    res.data.increasedCustomerInfoDistributionList.forEach(element => {
                        customList.push({
                            name:element.sourceFlagStr,
                            value:element.customerCount,
                        })
                    });
                    this.customList=customList
                    this.customFormData = res.data
                    this.customTypePie()
                }
            });
        },
        //线索类型分布
        initChart() {
            var myChart = this.$echarts.init(document.getElementById('clueTypePie'));
            this.clueChart=myChart
            var echartData =this.clueList
            let option = {
                // 标题组件，包含主标题和副标题。
                title: {
                    text: '线索类型分布',
                    textStyle: {
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#222222'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                // 图例组件
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    bottom: '0px',
                    width: '100%',
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: '55%',
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false
                        },
                        data: echartData
                    }
                ],
            };
            myChart.setOption(option, true)
            this.clueChart.on('click',()=>{
                jumpAppointRouter('asset','/asset/clueManagement')
            })
        },
        //客户类型分布
        customTypePie() {
            var myChart = this.$echarts.init(document.getElementById('customTypePie'));
            this.customChart=myChart
            var echartData =this.customList
            let option = {
                // 标题组件，包含主标题和副标题。
                title: {
                    text: '客户类型分布',
                    textStyle: {
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#222222'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                color: this.colorList,
                // 图例组件
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    bottom: '0px',
                    width: '100%',
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: '55%',
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false
                        },
                        data: echartData
                    }
                ],
            };
            myChart.setOption(option, true)
            this.customChart.on('click',()=>{
                jumpAppointRouter('asset','/asset/customerManagement')
            })
        },
        //跳转页面地址
        jumpRouter(moduleName, path){
            jumpAppointRouter(moduleName,path)
        }
    }

}
</script>

<style lang="scss" scoped>
.displayFlexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.displayFlex {
    display: flex;
}

.viewTitle {
    padding-bottom: 16px;
    border-bottom: 1px solid #EEEEEE;
    margin-top: 40px;
    // border-top: 1px solid #EEEEEE;
    // padding-top: 20px;

    .title {
        font-weight: 500;
        font-size: 18px;
        color: #222222;
        display: flex;
        align-items: center;
    }

    .projectTitle {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }
}



.marginLeft4 {
    margin-left: 4px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginLeft80 {
    margin-left: 80px;
}

.housingResources {
    padding: 16px 0px;
    align-items: center;
}

.graphsBox {
    height: inherit;
    display: flex;
    align-items: center;

    .graphsTitleView {
        background: #FAFAFA;
        flex: 1;
        padding: 20px 0px 20px 20px;
        cursor: pointer;

        .graphsTitleViewTitle {
            display: flex;
            align-items: center;

            .title {
                margin-left: 4px;
            }
        }

        .graphsTitleViewSpace {
            margin-top: 16px;

            .number {
                font-size: 22px;
                color: #222222;
                font-weight: 700;
            }

            .unit {
                font-size: 12px;
                color: #555555;
                margin-left: 4px;
            }
        }
    }
}

.flex1 {
    flex: 1;
}

.graphsTitle {
    font-size: 14px;
    color: #222222;
    font-weight: 500;
}

.iconImg {
    height: 20px;
    width: 20px;
}

.switchBox {
    width: 108px;
    height: 28px;
    background: #F2F3F8;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 20px 16px 24px;

    .switchView {
        font-weight: 400;
        font-size: 12px;
        height: 22px;
        line-height: 22px;
        width: 48px;
        text-align: center;
        cursor: pointer;
    }

    .switchActive {
        color: #1664FF;
        background: #FFFFFF;
        border-radius: 2px;
    }
}
</style>