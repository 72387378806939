<!--
 * @Author: lizhijie429
 * @Date: 2021-08-25 20:56:20
 * @LastEditors: lizhijie429
 * @LastEditTime: 2021-08-25 21:05:45
 * @Description: 
-->
<template>
  <div>
    <template v-for="item in subMenu">
      <el-submenu
        v-if="item.children && item.children.length > 0 && item.name !== 'notfound' && item.menuType !== 'C'"
        :key="item.menuName"
        :index="item.menuPath"
        v-show="!item.visible"
      >
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span style="padding-left: 10px">{{ item.menuName }}</span>
        </template>
        <sub-menu :subMenu="item.children"></sub-menu>
      </el-submenu>
      <el-menu-item
        v-else-if="item.name !== 'notfound'"
        :key="item.menuName"
        :index="item.menuPath"
        @click="handleSelect(item)"
        v-show="!item.visible ? (item.menuType === 'M' ? false : true) : !item.visible"
      >
        <!-- <div> -->
        <i class="el-icon-menu"></i>
        <span style="padding-left: 10px">{{ item.menuName === 'home' ? '工作台' : item.menuName }}</span>
        <!-- </div> -->
      </el-menu-item>
    </template>
  </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex';
import { userInfo } from '@/utils/userInfo';
import { getLocalStorage } from '@/utils/storage';
import { encode } from '@/utils/base64';
import { getAllProjectList } from '@/api/baseSet/projectManage';
export default {
  name: 'SubMenu',
  props: {
    subMenu: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      currentPage: (state) => state.permission.currentPage,
      tabsList: (state) => state.tabs.tabsList,
    }),
  },
  methods: {
    ...mapMutations({
      setList: 'space/setList'
    }),
    handleSelect(item) {
      if(item.menuName === '空间管理'){
        localStorage.removeItem('selectedOption')
        localStorage.removeItem('selectedName')
        this.$store.dispatch('space/getTreeProject',item.roleId)
        // // 查询父级 select 项目
      }
      if (
        item.menuName === '数据驾驶舱' ||
        item.menuName === '看板中心' ||
        item.menuName === '神策管理' ||
        item.menuName === '分析报告'
      ) {
        this.$sensors.track('PlatformMenu', {
          ...userInfo(),
          tab_name: item.menuName || '',
        });
      }
      if (item.menuPath.indexOf('http') === -1) {
        this.$store.commit('tabs/UPDATE_TABS_LIST', item);
        this.$actions.setGlobalState({ tabsList: this.tabsList });
        // 跳转进行逻辑判断 是否是内置链接页面
        if (item.linkPath) {
          const tempMenuPathArr = item.menuPath.split('?');
          const path = tempMenuPathArr[0];
          let param;
          if (tempMenuPathArr[1]) {
            param = tempMenuPathArr[1].split('=')[1];
          }
          if (param) {
            this.$router.push({
              path,
              query: {
                linkType: param,
              },
            });
          } else {
            this.$router.push({
              path,
            });
          }
        } else {
          this.$router.push(item.menuPath);
        }
      } else {
        if (item.menuPath.includes('COMMON_TOKEN')) {
          window.open(this.replaceIdentifierAndEncryption(item.menuPath));
        } else {
          window.open(item.menuPath);
        }
      }
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},

    // 跳转周边服务链接要拼接commonToken, 所以要对标识符进行替换成本地commonToken并base64加密
    replaceIdentifierAndEncryption(path) {
      const user = getLocalStorage('user');
      const encryptToken = encode(user.commonToken);
      return path.replace('COMMON_TOKEN', encryptToken);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu-item.is-active {
  border-right: 4px solid #1677ff;
  font-weight: 600;
  background-color: #ecf5ff;
}
</style>
