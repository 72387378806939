/* 网络请求 */
// import http from "@/utils/axios";

/* 这个是为了请求本地json文件-------临时使用 */
// import http from "axios";
import { constantRoutes } from '@/router';
import Layout from '@/views/Layout';
import { getLeftMenu } from '@/api/system/menuManage';
import { routeRegistered } from '@/router/registered';
// import menuJson from "../../../public/mock/menu.json"
let linkPathList = []
const permission = {
  namespaced: true,
  state: () => ({
    routers: null,
    menuList: [],
    subMenu: [],
    currentModuleName: 'home',
    currentPage: null,
    currentQuery: null,
    buttonPermissions: [],
    linkPathList: []
  }),
  mutations: {
    UPDATE_CURRENT_MODULE_NAME(state, payload) {
      sessionStorage.setItem('currentApp', payload);
      state.currentModuleName = payload;
    },
    UPDATE_CURRENT_PAGE(state, payload) {
      sessionStorage.setItem('currentPage', payload);
      state.currentPage = payload;
    },
    UPDATE_MENU_LIST(state, payload) {
      state.menuList = payload;
    },
    UPDATE_SUB_MENU(state, payload) {
      state.subMenu = [];
      const homeMenuData = {
        title: '工作台',
        menuName: 'home',
        menuPath: '/home',
        meta: { isTabs: false, isSide: true, moduleName: 'main', title: '工作台' },
      };
      if (typeof payload === 'boolean') {
        state.subMenu.push(homeMenuData);
        return;
      }
      if (typeof payload === 'object') {
        state.subMenu = payload;
      }
    },
    UPDATE_ROUTERS(state, payload) {
      state.routers = constantRoutes.concat(payload);
    },
    UPDATE_CURRENT_QUERY(state, payload) {
      sessionStorage.setItem('currentQuery', JSON.stringify(payload));
      state.currentQuery = payload;
    },
    UPDATE_BUTTON_PERMISSIONS(state, payload) {
      state.buttonPermissions = payload;
    },
    UPDATE_LINK_PATH_LIST(state, payload) {
      state.linkPathList = payload;
    }
  },
  actions: {
    async generateRoutes({ commit }) {
      try {
        var result = await getLeftMenu();
        if (result.code === '200') {
          return new Promise((resolve) => {
            const menuList = result.data;
            // stairCatalogueSort(menuList);
            commit('UPDATE_BUTTON_PERMISSIONS', conformityButtonPermissions(menuList));
            commit('UPDATE_MENU_LIST', menuList);
            let routes = [];
            for (let i = 0; i < menuList.length; i++) {
              const module = menuList[i];
              const route = getMenuItem(module.menuList);
              routes = [...routes, ...route];
            }
            routes.push({
              path: '*',
              name: 'notfound',
              component: () => import('@/views/404.vue'),
            });
            commit('UPDATE_ROUTERS', routes);
            console.log('-----');
            console.log(linkPathList);
            console.log('-----');
            commit('UPDATE_LINK_PATH_LIST', linkPathList)
            resolve(routes);
          });
        }
      } catch (error) {}
    },
  },
  getters: {
    getButtonPermissions: (state) => state.buttonPermissions,
  },
};

// 组装路由数据
function getMenuItem(menus) {
  let routers = [];
  // 内置链接集合
  const tempLinkPathList = [];
  for (let index = 0; index < menus.length; index++) {
    const menu = menus[index];
    if (menu.children && menu.children.length > 0) {
      if (menu.menuType === 'C') {
        let component = null;
        if (menu.moduleName === 'main') {
          component = routeRegistered[menu.component];
        } else {
          component = Layout;
        }

        const route = {
          path: menu.menuPath,
          component: component,
          name: menu.menuName,
          meta: menu.meta ? menu.meta : {},
        };
        route.meta.title = menu.menuName;
        route.meta.moduleName = menu.moduleName;
        route.meta.roleId = menu.roleId;
        routers.push(route);
      }
      routers = [...routers, ...getMenuItem(menu.children)];
    } else {
      let component = null;
      if (menu.moduleName === 'main') {
        component = routeRegistered[menu.component];
      } else {
        component = Layout;
      }
      // 判断是否是有内置链接地址 如果有根据规则要对路由地址进行拆分
      let route;
      if (menu.linkPath) {
        route = {
          path: menu.menuPath.split('?')[0],
          component: component,
          name: menu.menuName,
          meta: menu.meta ? menu.meta : {},
        };
        // 对内置链接数据进行处理
        if (menu.menuPath.split('?')[1]) {
          const linkType = menu.menuPath.split('?')[1].split('=')[1];
          tempLinkPathList.push({
            linkType,
            linkPath: menu.linkPath,
            linkTitle: menu.menuName
          })
        }
      } else {
        route = {
          path: menu.menuPath,
          component: component,
          name: menu.menuName,
          meta: menu.meta ? menu.meta : {},
        };
      }
      route.meta.title = menu.menuName;
      route.meta.moduleName = menu.moduleName;
      route.meta.roleId = menu.roleId
      if (menu.menuPath.indexOf('http') === -1) {
        routers.push(route);
      }
    }
  }
  linkPathList.push(...tempLinkPathList)
 
  return routers;
}

// TODO: 此处为菜单目录的排序, 但已经没有使用. 现在的菜单排序是服务端进行处理, 保留此处代码原因不确定之后还需不需要前端进行排序
//目录排序
function stairCatalogueSort(menuList) {
  menuList.forEach((item) => {
    if (item.menuList && item.menuList.length > 1) {
      item.menuList = item.menuList.sort(function (a, b) {
        return a.orderNum - b.orderNum;
      });
    }
    item.menuList.forEach((item) => {
      if (item.children && item.children.length) {
        menuSort(item);
      }
    });
  });
}
//子菜单排序
function menuSort(item) {
  if (item.children && item.children.length > 1) {
    item.children = item.children.sort(function (a, b) {
      return a.orderNum - b.orderNum;
    });
  }
  if (item.children && item.children.length) {
    item.children.forEach((item1) => {
      menuSort(item1);
    });
  }
}

// 整合所有项目的按钮权限标识
function conformityButtonPermissions(menuList) {
  const buttonPermissions = [];
  menuList.forEach((item) => {
    if (item.authorityKeyList?.length) {
      buttonPermissions.push(...item.authorityKeyList);
    }
  });
  return buttonPermissions;
}

export default permission;
