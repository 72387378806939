import { getLocalStorage } from "@/utils/storage";
import { USER_KEY } from "@/config";
import { getAllProjectList } from '@/api/baseSet/projectManage';
const user = {
  namespaced: true,
  state: {
    proList:JSON.parse(localStorage.getItem('proList')) || []
  },
  mutations: {
    setList(state, payload) {
      state.proList= payload
      localStorage.setItem('proList',JSON.stringify(payload))
    },
  },
  actions: {
   async  getTreeProject({commit},id){
    let res = await getAllProjectList({
      roleId:id
    })
    //递归把子级变成平级
    let result = [];
    const traverse = (nodeList) => {
      for (let node of nodeList) {
        result.push({
          id: node.id,
          parent_id: node.parent_id,
          project_name: node.project_name,
          disabled: node.disabled,
          penetrate: node.penetrate,
        });
        if (node.child) {
          traverse(node.child);
        }
      }
    };
    traverse(res.data);
    commit('setList',result)
   }
  },
};
export default user;
