<template>
  <div class="tabs-box flex-row flex-items-center">
    <div
      class="tabs-item paas-flex-cc"
      v-for="item in tabsList"
      :key="item.menuName"
      :class="checkIsSelectedMenu(item) ? 'tabs-item-hover' : 'null'"
    >
      <span>
        <span class="cursor-pointer fs-14 tabs-item-text" @click="handleClick(item)"
          >{{ item.menuName === 'home' ? '工作台' : item.menuName }}&nbsp;</span
        >
        <a class="el-icon-close" @click="removeTab(item)" href="javascript:;"></a>
        <!-- <i class="el-icon-close" @click="removeTab(item)"></i> -->
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      menuList: (state) => state.permission.menuList,
      currentPage: (state) => state.permission.currentPage,
      tabsList: (state) => state.tabs.tabsList,
      currentQuery: (state) => state.permission.currentQuery,
    }),
  },
  data() {
    return {};
  },
  methods: {
    // 获取元素数组下标
    getArrayIndex(arr, obj) {
      for (let index = 0; index < arr.length; index++) {
        const item = arr[index];
        if (item.authority === obj) {
          return index;
        }
      }
      return -1;
    },
    // 跳转tabs标签
    handleClick(item) {
      // 进行逻辑判断  是否是内置链接跳转相同路由
      if (item.linkPath) {
        if (this.currentPage === item.menuPath.split('?')[0] && this.currentQuery.linkType === item.menuPath.split('?')[1].split('=')[1])
          return false;
      } else {
        if (this.currentPage === item.menuPath) return false;
      }
      // 设置左侧菜单数据
      if (item.menuPath !== '/home') {
        const menu = this.menuList.filter((element) => {
          return element.moduleName === item.moduleName;
        });
        this.$store.commit('permission/UPDATE_SUB_MENU', menu[0].menuList);
        this.$router.push({ path: item.menuPath });
      } else {
        this.$store.commit('permission/UPDATE_SUB_MENU', true);
        this.$router.push({ path: item.menuPath });
      }
    },
    // 删除tabs标签
    removeTab(item) {
      let tabsListLength = this.tabsList.length - 1;
      // 获取当前要删除的tabs的位置
      let indexOf = this.getArrayIndex(this.tabsList, item.authority);
      if (tabsListLength === indexOf) {
        // 删除最后一个
        this.$store.dispatch('tabs/REMOVE_LAST_TAB', item);
      } else {
        // 删除除了第一个跟最后一个
        this.$store.dispatch('tabs/REMOVE_ANY_TAB', {
          indexOf,
          tabsItem: item,
        });
      }
    },
    // 校验是否是选中菜单
    checkIsSelectedMenu(item) {
      if (this.currentPage === item.menuPath) {
        return true;
      } else if (item.linkPath) {
        const menuPath = item.menuPath.split('?')[0];
        const query = item.menuPath.split('?')[1].split('=')[1];
        if (this.currentPage === menuPath && this.currentQuery.linkType === query) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// .tabs-box {
//   height: 0.5rem;
//   padding: 0.1rem;
//   .tabs-item {
//     height: 100%;
//     padding: 0 0.12rem;
//     margin-right: 0.1rem;
//     background-color: #fff;
//     border-radius: 0.04rem;
//     color: #333;
//     font-size: 0.14rem;
//     line-height: 0.32rem;
//     white-space: nowrap;
//     .tabs-item-text {
//       &:hover {
//         color: #23b7e5;
//       }
//     }
//     .tabs-remove-icon {
//       margin-left: 0.02rem;
//       padding: 0.02rem;
//       font-size: 0.04px;
//       cursor: pointer;
//     }
//     .tabs-remove-icon:hover {
//       border-radius: 50%;
//       color: #fff;
//       background-color: #23b7e5;
//     }
//   }
//   .tabs-item:last-child {
//     border-right: none !important;
//   }
// }
// .tabs-item-hover {
//   color: #23b7e5 !important;
// }

.tabs-box {
  height: 50px;
  padding: 10px;
  .tabs-item {
    height: 30px;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 3px;
    color: #333;
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    .tabs-item-text {
      &:hover {
        color: #23b7e5;
      }
    }
    .tabs-remove-icon {
      margin-left: 0.02rem;
      padding: 0.02rem;
      font-size: 0.04px;
      cursor: pointer;
    }
    .tabs-remove-icon:hover {
      border-radius: 50%;
      color: #fff;
      background-color: #23b7e5;
    }
  }
  .tabs-item:last-child {
    border-right: none !important;
  }
}
.tabs-item-hover {
  color: #23b7e5 !important;
}
</style>
