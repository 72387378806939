

export default {
  // 项目管理
  projectManage: {
    // 添加
    add: "baseSet_projectManage_add",
    // 查看
    look: "baseSet_projectManage_look",
    // 编辑
    edit: "baseSet_projectManage_edit",
    // 删除
    delete: "baseSet_projectManage_delete",
    // 添加
    addChild: "baseSet_projectManage_add",
  },
  spaceManage:{
    // 添加
    add: "baseSet_spaceManage_add",
    // 查看
    look: "baseSet_spaceManage_look",
    // 编辑
    edit: "baseSet_spaceManage_edit",
    // 删除
    delete: "baseSet_spaceManage_delete",
    // 添加
    addChild: "baseSet_spaceManage_add",
  }
};
