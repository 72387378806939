// 服务地址;
let option = {
  authority: "/api/dscloud-authority-center", //权限
  platform: "/api/dscloud-saas-platform", //Saas服务地址
  project: "/api/project", //项目服务地址
  account: "/api/dscloud-account-center", //账户
  analysis: "/api/data-analysls-platform", // 数据分析服务
  cs: "/hou",
  space:'/api/space'
};

//请求地址
export default {
  TEXT: option.cs + "/oauthUserInfoController/test",
  /**菜单管理 */
  ADD_MENU: option.authority + "/menu/add", //添加菜单
  GET_MENU_LIST: option.authority + "/menu/list", //获取菜单列表
  DELETE_MENU: option.authority + "/menu/delete", //删除菜单
  // 组织管理
  ORGANIZATION_LIST: option.platform + "/organization/listOrganizationAll", // 组织架构
  ORGANIZATION_TABLE: option.platform + "/organization/getAll", // 组织架构列表
  ORGANIZATION_ADD: option.platform + "/organization/add", // 新建组织
  ORGANIZATION_EDIT: option.platform + "/organization/update", // 编辑组织 
  ORGANIZATION_SEARCHID: option.platform + "/organization/getParentNameByOrganizationId", // 查询上级组织
  ORGANIZATION_DETAIL: option.platform + "/organization/getInfo", // 组织详情
  ORGANIZATION_USERLIST: option.platform + "/organization/getUserList", // 已关联和未关联组织人员
  ORGANIZATION_DELETE: option.platform + "/organization/delete", // 删除组织
  ORGANIZATION_ADDUSERORG: option.platform + "/organization/addUserOrg", // 添加用户组织关联
  ORGANIZATION_TENANTID: option.platform + "/organization/getTenantId",//获取租户id

  //运营管理平台--用户管理
  USER_MESSAGE_LIST: option.account + "/oauthUserInfoController/getUserList", // 用户管理列表
  USER_MESSAGE_DETAIL: option.platform + "/oauthUserInfoController/getUserInfo", // 用户详情
  USER_MESSAGE_EDIT: option.platform + "/oauthUserInfoController/updateUserProject", // 编辑用户
  USER_MESSAGE_FREEZE: option.platform + "/oauthUserInfoController/freezeUser", //冻结/解冻用户
  USER_MESSAGE_EXPORT: option.platform + "/user/export", //导出
  // LOGIN: option.authority + "/login", //登录
  EDIT_MENU: option.authority + "/menu/update", //编辑菜单
  /**租户管理 */
  ADD_LESSEE: option.platform + "/tenant/add", //添加租户
  GET_LESSEE_LIST: option.platform + "/tenant/list", //获取租户列表
  GET_LESSEE_DETAIL: option.platform + "/tenant/getByTenantId", //查看租户详情
  EDIT_LESSEE_INFO: option.platform + "/tenant/update", //编辑租户信息
  DELETE_LESSEE: option.platform + "/tenant/delete", //删除租户
  DISABLE_LESSEE: option.platform + "/tenant/disable", //禁用租户
  /**获取平台数据 */
  GET_PLATFORM_LIST: option.platform + "/platform/findAll", //获取平台数据
  /**登录 */
  LOGIN: option.authority + "/login", //登录
  /** 通过手机号发送短信 */
  SEND_NOTE: option.authority + '/login/oauth/sendMsg',
  /**验证验证码是否正确 */
  CHECK_NOTE: option.authority + '/login',
  /**角色管理 */
  GET_LEFT_MENU: option.authority + "/userRole/getMenuList", //获取左侧显示菜单
  GET_ROLE_LIST: option.authority + "/role/list", //获取权限列表
  GET_CURRENT_ROLE_PROJECT_LIST: option.authority + "/user/getProject", //获取当前人在当前菜单可查询目
  ADD_ROLE: option.authority + "/role/add", //添加角色
  ROLE_DISABLE: option.authority + "/role/disable", //角色启禁用
  DELETE_ROLE: option.authority + "/role/delete", //删除角色
  GET_ROLE_DETAIL: option.authority + "/role/getRoleInfoById", //获取角色详情
  EDIT_ROLE: option.authority + "/role/update", //编辑角色
  GET_CURRENT_LESSEE_IN_CURRENT_PLATFORM_SHOW_MENU_LIST: option.authority + "/userRole/findMenuByPlatformId", //获取当前租户在当前项目下的数可选择菜单
  /**项目管理 */
  GET_PROJECT_LIST: option.project + "/project/list", //获取项目列表
  ADD_PROJECT: option.project + "/project", //添加项目
  GET_PROJECT_DETAIL: option.project + "/project", //获取项目详情
  DELETE_PROJECT: option.project + "/project", //删除项目
  EDIT_PROJECT: option.project + "/project", //编辑项目
  /**账户管理 */
  ADD_ACCOUNT: option.account + "/accountManagementController/add", //添加账户
  GET_ACCOUNT_LIST: option.account + "/accountManagementController/listAccount", //获取账户列表
  GET_ACCOUNT_DETAIL: option.account + "/accountManagementController/getByUserId", //获取账户详情
  EDIT_ACCOUNT: option.account + "/accountManagementController/update", //编辑账户
  ACCOUNT_DISABLE: option.account + "/accountManagementController/disable", //账户启禁用
  DELETE_ACCOUNT: option.account + "/accountManagementController/delete", //删除账户
  USER_MESSAGE_LIST: option.account + "/oauthUserInfoController/getUserList",
  ORGANIZATION_ORGANIZATIONTREE: option.platform + "/organization/organizationTree",//组织架构-树形结构
  //判断用户是否是i友工作台用户
  USER_CHECK: option.account + "/oauth/getUserInfoByCommonToken",

  /** 数据分析服务 */
  // 获取dashboard报表
  GET_DASHBOARD_REPORT: option.analysis + "/web/dashboard/list", 
  // 获取看板报告
  GET_KANBAN_REPORT: option.analysis + "/web/kanban/center/list", 
  GETTREESPACEINFOLIST:option.space + '/space/getTreeSpaceInfoList',//社区树
  SPACE_GETSPACELIST:option.space + '/space/getAllSonSpaceList',//社区列表
  GETPROPERTYCERTIFICATENUMBERLIST:option.space + '/space/getPropertyCertificateNumberList' ,//房产证
  SPACE_CREATESPACE:option.space + '/space/createSpace' ,//创建空间
  SPACE_COUNTAREA:option.space + '/space/countArea' ,//维护面积
  SPACE_GETSPACEDETAILINFO:option.space + '/space/getSpaceDetailInfo' ,//空间详情以及附属信息
  SPACE_DELETESPACE:option.space + '/space/deleteSpace' ,//空间删除
  SPACE_UPDATESPACE:option.space + '/space/updateSpace' ,//空间编辑
  GET_SHUNYI_ALL_PROJECTLIST: option.project + '/saas/project/userRole/treeProjectList',
};
