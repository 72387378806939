<template>
  <a-button :type="showBtnType.btnType" v-on="$listeners">
    <a-icon :type="showBtnType.iconType" v-if="showIcon" />
    {{ showBtnType.title }}
  </a-button>
</template>

<script>

/**按钮类型配置
 * DS_ADD: 添加
 * DS_SEARCH: 查询
 * DS_EXPORT: 导出
 * DS_UPLOAD: 批量上传
 * DS_RESET: 重置
 * DS_SAVE: 保存
 * DS_SUBMIT: 提交
 * DS_BACK: 返回
 * DS_DEFAULT: 项目默认主按钮(如果选择该样式, 必须要传title)
 * DS_CANCEL: 项目默认取消按钮
 * DS_CONFIRM: 确定
 */
const BtnConfig = {
  DS_ADD: "add",
  DS_SEARCH: "search",
  DS_EXPORT: "export",
  DS_UPLOAD: "batchUpload",
  DS_RESET: "reset",
  DS_SAVE: "save",
  DS_SUBMIT: "submit",
  DS_BACK: "back",
  DS_DEFAULT: "default",
  DS_CANCEL: "cancel",
  DS_CONFIRM: "confirm"
}
export default {
  name: "dsButton",
  props: {
    // 按钮文字
    title: {
      type: String,
      default: "",
    },
    // 按钮类型
    type: {
      type: String,
      default: undefined,
    },
    // 是否显示按钮icon, 默认显示
    isShowIcon: {
      type: Boolean,
      default: true,
    },
    // 是否是行内块样式, 默认是行内块样式
    inLine: {
      type: Boolean,
      default: true,
    },
    // 自定义图标样式
    icon: {
      type: String,
      default: undefined,
    },
    // 自定义按钮样式
    btnType: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    // 计算出是否显示icon图标
    showIcon() {
      if (!this.isShowIcon) {
        return this.isShowIcon;
      } else if (this.showBtnType.iconType) {
        return true;
      } else {
        return false;
      }
    },
    // 计算按钮类型
    showBtnType() {
      return this.returnBtnType();
    },
  },
  methods: {
    // 返回按钮样式
    returnBtnType() {
      let title = undefined;
      let btnType = "primary";
      let iconType = undefined;
      if (this.type === BtnConfig.DS_ADD) {
        iconType = "plus";
        title = "添加";
      } else if (this.type === BtnConfig.DS_SEARCH) {
        iconType = "search";
        title = "查询";
      } else if (this.type === BtnConfig.DS_EXPORT) {
        title = "导出";
      } else if (this.type === BtnConfig.DS_UPLOAD) {
        title = "批量上传";
      } else if (this.type === BtnConfig.DS_RESET) {
        title = "重置";
        btnType = undefined;
        iconType = "redo"
      } else if (this.type === BtnConfig.DS_SAVE) {
        title = "保存";
      } else if (this.type === BtnConfig.DS_SUBMIT) {
        title = "提交";
      } else if (this.type === BtnConfig.DS_BACK) {
        title = "返回";
        btnType = undefined;
      } else if (this.type === BtnConfig.DS_CANCEL) {
        title = "取消";
        btnType = undefined;
      } else if (this.type === BtnConfig.DS_CONFIRM) {
        title = "确定";
      }

      if (this.title) {
        title = this.title;
      }
      if (this.icon) {
        iconType = this.icon;
      }
      if (this.btnType) {
        btnType = this.btnType;
      }
      return {
        title,
        btnType,
        iconType,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.inlineBlock {
  display: inline-block;
}
</style>
