<template>
  <el-container style="height: 100vh">
    <el-aside width="240px" style="text-align: left">
      <side-menu></side-menu>
    </el-aside>
    <el-container style="min-width: 960px">
      <el-header>
        <nav-menu></nav-menu>
      </el-header>
      <tabs v-if="showTabs"></tabs>
       <div style="height: 10px;" v-else></div>
      <el-main>
        <slot></slot>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from 'vuex';
import { SideMenu, NavMenu, Tabs } from './components/index';
export default {
  name: 'Layout',
  components: {
    SideMenu,
    NavMenu,
    Tabs,
  },
  computed: {
    ...mapState({
      menuList: (state) => state.permission.menuList,
      tabsList: (state) => state.tabs.tabsList,
    }),
  },
  data() {
    return {
      isConfirm: false,
      showTabs: false,
    };
  },
  mounted() {
    // 初始化全局下发的数据
    this.$actions.setGlobalState({
      userInfo: this.$store.state.user.userInfo,
      globalConfig: this.$store.state.user.globalConfig,
      routers: this.$store.state.permission.routers,
      buttonPermissions: this.$store.state.permission.buttonPermissions,
      linkPathList: this.$store.state.permission.linkPathList,
      menuList: this.$store.state.permission.menuList
    });
    //buttonPermissions: this.$store.state.permission.buttonPermissions
    // 获取页面持久化数据
    const currentPage = sessionStorage.getItem('currentPage');
    const currentApp = sessionStorage.getItem('currentApp');
    const currentQuery = sessionStorage.getItem('currentQuery');
    // 处理关闭前页面是首页的情况
    const homeMenuData = {
      title: '工作台',
      menuName: 'home',
      menuPath: '/home',
      meta: { isTabs: false, isSide: true, moduleName: 'main', title: '工作台' },
    };
    if (currentPage && currentPage === '/home' && currentApp && currentApp === 'main') {
      this.$store.commit('permission/UPDATE_SUB_MENU', true);
      this.$store.commit('tabs/UPDATE_TABS_LIST', homeMenuData);
      this.$actions.setGlobalState({ tabsList: this.tabsList });
      return false;
    }
    // 处理关闭前非首页页面持久化逻辑
    if (currentPage && currentApp) {
      // 获取左侧菜单数据
      const menu = this.menuList.filter((element) => {
        return element.moduleName === currentApp;
      });
      this.$store.commit('permission/UPDATE_SUB_MENU', menu[0].menuList);
      // 跳转页面
      this.inquireCurrentMenu(menu[0].menuList, currentPage, currentQuery);
      const page = this.isConfirm;
      this.$store.commit('tabs/UPDATE_TABS_LIST', page);
    } else {
      this.$store.commit('permission/UPDATE_SUB_MENU', true);
      this.$store.commit('tabs/UPDATE_TABS_LIST', homeMenuData);
    }
    this.$actions.setGlobalState({ tabsList: this.tabsList });
  },
  methods: {
    //递归路由集合查找当前点击菜单
    inquireCurrentMenu(routers, currentPage, currentQuery) {
      let selectedPage = {};
      routers.forEach((element) => {
        if (element.children && element.children.length) {
          selectedPage = this.inquireCurrentMenu(element.children, currentPage, currentQuery);
        }
        if (element.menuPath === currentPage) {
          selectedPage = element;
          this.isConfirm = selectedPage;
        } else if (element.linkPath) {
          const menuPath = element.menuPath.split('?')[0];
          const query = element.menuPath.split('?')[1].split('=')[1];
          if (currentPage === menuPath && JSON.parse(currentQuery).linkType === query) {
            selectedPage = element;
            this.isConfirm = selectedPage;
          }
        }
      });
      return selectedPage;
    },
  },
  created() {
    if (window.location.href.includes('kcjt')) {
      this.showTabs = false;
    } else {
      this.showTabs = true;
    }
    
  },
};
</script>

<style lang="scss">
.el-header,
.el-footer {
  padding: 0;
  color: #fff;
  text-align: center;
  background-color: #002140;
}
.el-main::-webkit-scrollbar {
  display: none;
}
.el-main {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.el-main {
  margin: 0 0.1rem 0.1rem;
  padding: 0.2rem;
  background-color: #fff;
}
.el-menu.el-menu--horizontal {
  background: #002140;
}
.el-menu--horizontal > .el-menu-item.is-active {
  color: #fff !important;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #fff !important;
  background-color: transparent !important;
}
</style>
